import React, { InputHTMLAttributes } from 'react';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  sizeClass?: string;
  fontClass?: string;
  rounded?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	(
		{
			className = '',
			sizeClass = 'h-11 px-4 py-3',
			fontClass = 'text-sm font-normal',
			rounded = 'rounded-2xl',
			children,
			type = 'text',
			...args
		},
		ref
	) => {
		return (
			<input
				ref={ref}
				type={type}
				className={`block w-full border-neutral-200 focus-dark bg-white
				/*focus:border-neutral-1000 focus:ring focus:ring-neutral-1000 focus:ring-opacity-90 dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25*/ 
				dark:bg-neutral-900 
				disabled:bg-neutral-200 dark:disabled:bg-neutral-800 ${rounded} ${fontClass} ${sizeClass} ${className}`}
				{...args}
			/>
		);
	}
);

export default Input;
