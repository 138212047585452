import { useCollection, useCollectionOnce, useDoc } from '@rainbow-modules/firebase-hooks';
import { userProfilesImagesBucket } from '../constants';
import useValidImageUrl from './useValidImageUrl';
import { useCallback } from 'react';
import { query, where, documentId } from 'firebase/firestore';

interface IUserLightData {
    uid: string;
    firstName?: string;
    lastName?: string;
    username?: string;
    email: string;
    createdAt?: Date;
}

const normalizeLightUserData = (data: any): IUserLightData => {
	return {
		uid: data.id,
		firstName: data.firstName,
		lastName: data.lastName,
		email: data.email,
		username: `@${data.username}`,
		createdAt: data.createdAt && typeof data.createdAt.toDate === 'function' ? data.createdAt.toDate() : undefined,
	};
};

const useUsers = (ids: string[]): [IUserLightData[], boolean] => {
	const filterFn = useCallback((docRef: any) => {
		if(ids && ids.length>0){
			return query((docRef as any), where(documentId(), 'in', ids));
		}
		return docRef;
	}, [ids]);

	const [data, isLoading] = useCollectionOnce<IUserLightData>({
		path: 'users',
		flat: true,
		query: filterFn,
		disabled: !ids
	});
	if (isLoading ) {
		return [[], true];
	}
	return [data.map(normalizeLightUserData), false];
};

export default useUsers;