import useHttpAuthMutation from './useHttpAuthMutation';
import { useCallback } from 'react';

const backendBaseUrl = process.env.REACT_APP_BACKEND_CARDS_BASE_URL;


const useResolveCardToken = (cardObjectId: string):[()=>Promise<unknown>, boolean]=> {
	const { mutate, isLoading } = useHttpAuthMutation({
		pathname: `/${cardObjectId}/use`,
		baseUrl: backendBaseUrl,
		method: 'POST',
	});
	const resolveCardToken = useCallback(async () => {
		return new Promise((resolve, reject) => {
			mutate({}, {
				onSuccess: (res) => {
					return resolve(res);
				},
				onError: (err) => {
					return reject(err);
				}
			});
		});
	}, [mutate,]);

	return [resolveCardToken, isLoading];
};

export default useResolveCardToken;
