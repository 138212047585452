import React from 'react';
import { Link } from 'react-router-dom';
import logoImg from 'images/icons/logoMain.svg';
import logoLightImg from 'images/icons/logoMainLight.svg';

export interface LogoProps {
    img?: string;
    imgLight?: string;
    className?: string;
}

const Logo: React.FC<LogoProps> = ({
	img = logoImg,
	imgLight = logoLightImg,
	className = '',
}) => {
	return (
		<Link
			to="/"
			className={`ttnc-logo inline-block text-primary-6000 ${className}`}
		>
			{img ? (
				<img
					className={`block max-h-32 ${imgLight ? 'dark:hidden' : ''}`}
					src={img}
					alt="Logo"
				/>
			) : (
				'Logo Here'
			)}
			{imgLight && (
				<img
					className="hidden max-h-32 dark:block"
					src={imgLight}
					alt="Logo-Light"
				/>
			)}
		</Link>
	);
};

export default Logo;
