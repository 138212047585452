import Button, { ButtonProps } from 'shared/Button/Button';
import React from 'react';

export type ButtonPrimaryProps = ButtonProps

const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
	className = '',
	...args
}) => {
	return (
		<Button
			className={`text-16px_semi ttnc-ButtonPrimary disabled:bg-opacity-70 bg-neutral-1000 hover:bg-primary-1002 dark:bg-primary-1002 dark:hover:bg-primary-1001 text-neutral-50 ${className}`}
			{...args}
		/>
	);
};

export default ButtonPrimary;
