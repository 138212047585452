import React, { FC } from 'react';
import appleSvg from 'images/Apple.svg';
import googleSvg from 'images/Google.svg';
import { Helmet } from 'react-helmet';
import Input from 'shared/Input/Input';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { Link } from 'react-router-dom';
import firebaseApp from '../../firebaseApp'; 
import { GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import Logo from '../../shared/Logo/LogoOnly';

export interface PageSignUpProps {
  className?: string;
}

const loginSocials = [
	{
		name: 'Continue with Apple',
		href: '#',
		icon: appleSvg,
		onCLick: async () => {
			const appleAuthProvider = new OAuthProvider('apple.com');
			await firebaseApp.auth().signInWithPopup(appleAuthProvider);
		}
	},
	{
		name: 'Continue with Google',
		href: '#',
		icon: googleSvg,
		onCLick: async () => {
			const googleAuthProvider = new GoogleAuthProvider();
			await firebaseApp.auth().signInWithPopup(googleAuthProvider);
		}
	},
];

const PageSignUp: FC<PageSignUpProps> = ({ className = '' }) => {
	const signUp = async (email: string, password: string) => {
		await firebaseApp.auth().createUserWithEmailAndPassword(email, password);
	};
	return (
		<div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
			<Helmet>
				<title>Login | ShowApp Marketplace</title>
			</Helmet>
			<div className="container mb-8 lg:mb-32">
				<div className="flex flex-col items-center mb-40px">
					<Logo />
					<h2 className=" flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
              Signup
					</h2>
				</div>
				<div className="max-w-md mx-auto space-y-6 ">
					<div className="grid gap-3">
						{loginSocials.map((item, index) => (
							<a
								key={index}
								href={item.href}
								onClick={item.onCLick}
								className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
							>
								<img
									className="flex-shrink-0"
									src={item.icon}
									alt={item.name}
								/>
								<h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
									{item.name}
								</h3>
							</a>
						))}
					</div>
					{/* OR */}
					<div className="relative text-center">
						<span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
						</span>
						<div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
					</div>
					{/* FORM */}
					<form className="grid grid-cols-1 gap-6" onSubmit={async (event) => {
						event.preventDefault();
						await signUp((event.target as any).elements.email.value, (event.target as any).elements.password.value);
					}}>
						<label className="block">
							<span className="text-neutral-800 dark:text-neutral-200">
                Email address
							</span>
							<Input
								type="email"
								name="email"
								placeholder="example@example.com"
								className="mt-1"
							/>
						</label>
						<label className="block">
							<span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
							</span>
							<Input type="password" className="mt-1" name="password"/>
						</label>
						<ButtonPrimary type="submit">Continue</ButtonPrimary>
					</form>

					{/* ==== */}
					<span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {' '}
						<Link to="/signup" className=" text-15px_semi text-primary-1004 text-decor">
              Sign in
						</Link>
					</span>
				</div>
			</div>
		</div>
	);
};

export default PageSignUp;
