import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

function parsePhoneNumber(phoneNumber: string, region = 'US') {
	const phone = phoneUtil.parseAndKeepRawInput(phoneNumber, region);
	if (phone && phoneUtil.isValidNumber(phone)) {
		return phoneUtil.format(phone, PhoneNumberFormat.E164);
	}
	return undefined;
}

export default parsePhoneNumber;
