import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export interface AuthState {
  value: boolean;
}

const initialState: AuthState = {value: false};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		changeAuthState: (
			state,
			action: PayloadAction<AuthState['value']>
		) => {
			return {
				...state,
				value: action.payload,
			};
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	changeAuthState,
} = authSlice.actions;

export const selectCurrentAuth = (state: RootState) =>
	state.auth.value;

export default authSlice.reducer;
