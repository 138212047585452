import React, { FC, useEffect, useState } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { useParams } from 'react-router-dom';
import useResolveCardToken from '../hooks/useCardToken';
import {ClipboardCopyIcon} from '@heroicons/react/outline';

export interface CardDetailPageProps {
    className?: string;
}

const backendBaseUrl = process.env.REACT_APP_BACKEND_CARDS_BASE_URL;

const CardWidgetIframe: FC<CardDetailPageProps> = ({
	className = '',
}) => {
	const { cardObjectId= '', token = '' } = useParams();
	const [resolveCardToken, isLoadingCardToken] = useResolveCardToken(cardObjectId);
	const [isLoadingCardPan, setIsLoadingCardPan] = useState(false);
	const [cardLoaded, setCardLoaded] = useState(false);
	const isDarkMode= localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches);

	const clearDomId = (domId: string) => {
		const element = document.getElementById(domId);
		if (element) {
			element.innerHTML = ''; // Clear the content of the element

			// Create a new <div> element
			const newDiv = document.createElement('div');
			newDiv.innerHTML = `
      <svg width="34" height="34" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="30" height="30" rx="15" fill="#29860B"/>
        <path d="M21.25 10L12.5 20L8.75 16.25" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    `;

			// Append the new <div> to the target element
			element.appendChild(newDiv);
		}
	};

	const handlePanCopySuccess = () => {
		clearDomId('mq-copy-card-pan');
	};

	const handleExpCopySuccess = () => {
		clearDomId('mq-copy-card-exp');
	};

	const handleCvvCopySuccess = () => {
		clearDomId('mq-copy-card-cvv');
	};
	const handleShowCard = async () => {
		setIsLoadingCardPan(true);
		(window as any).marqeta.bootstrap({
			clientAccessToken: (token as any),
			component: {
				showPan: {
					'cardPan': {
						'domId': 'mq-card-pan',
						'format': true,
						'styles': {
							'span': {
								'font-family': 'OCR A Std, monospace',
								'line-height': '1',
								'font-weight': '400',
								'font-size': '15px',
								'color': isDarkMode ? 'white' : 'black'
							}
						}
					},
					'copyCardPan': {
						'domId': 'mq-copy-card-pan',
						'mode': 'transparent',
						'onCopySuccess': handlePanCopySuccess,
						// 'onCopyFailure': (error: any) => {
						// 	console.error(error);
						// }
					},
					'cardExp': {
						'domId': 'mq-card-exp',
						'format': true,
						'styles': {
							'span': {
								'font-family': 'OCR A Std, monospace',
								'line-height': '1',
								'font-weight': '400',
								'font-size': '15px',
								'color': isDarkMode ? 'white' : 'black'
							}
						}
					},
					'copyCardExp': {
						'domId': 'mq-copy-card-exp',
						'mode': 'transparent',
						'onCopySuccess': handleExpCopySuccess,
						// 'onCopyFailure': (error: any) => {
						// 	console.error(error);
						// }
					},
					'cardCvv': {
						'domId': 'mq-card-cvv',
						'styles': {
							'span': {
								'font-family': 'OCR A Std, monospace',
								'line-height': '1',
								'font-weight': '400',
								'font-size': '15px',
								'color': isDarkMode ? 'white' : 'black'
							}
						}
					},
					'copyCardCvv': {
						'domId': 'mq-copy-card-cvv',
						'mode': 'transparent',
						'onCopySuccess': handleCvvCopySuccess,
						// 'onCopyFailure': (error: any) => {
						// 	console.error(error);
						// }
					}
				}
			},
			'callbackEvents': {
				'onSuccess': () => {
					setCardLoaded(true);
					setIsLoadingCardPan(false);
				},
				'onFailure': (error: any) => {
					setIsLoadingCardPan(false);
				}
			}
		});
	};

	useEffect(() => {
		if (cardObjectId && token) {
			handleShowCard();
		}
	}, [cardObjectId, token]);

	return (
		<div className='mlr-40 full-screen'>
			<p className="text-h3 mb-4px font-semibold text-neutral-900 dark:text-neutral-200">
                Card information
			</p>
			<p className='text-15px_reg text-neutral-700'>This card is exclusively valid at the affiliated business. Use it for secure online payments or add to your Apple Wallet for convenient transactions.</p>
			<div className='card-number'>
				<div className='grid-border'>
					<div className='grid grid-col-size align-center mb-12px'>
						<label className="block text-sm font-medium text-neutral-700 dark:text-neutral-200">Card number</label>
						<div id='mq-card-pan' className='card-info-container'></div>
						<div id='copy-pan-container' className='clipboard-copy-container' style={{position: 'relative'}}>
							<div id="mq-copy-card-pan" style={{position: 'relative'}} >
								<svg width="34" height="34" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect width="40" height="40" rx="20" fill="#5B86E5" fill-opacity="0.2"/>
									<path d="M24 8V12.5333C24 13.2801 24 13.6534 24.1454 13.9387C24.2732 14.1895 24.4772 14.3935 24.7281 14.5213C25.0133 14.6667 25.3866 14.6667 26.1334 14.6667H30.6667M17.3334 14.6667H12C10.5273 14.6667 9.33337 15.8606 9.33337 17.3333V29.3333C9.33337 30.8061 10.5273 32 12 32H20C21.4728 32 22.6667 30.8061 22.6667 29.3333V25.3333M25.3334 8H21.6C20.1066 8 19.3598 8 18.7894 8.29065C18.2876 8.54631 17.8797 8.95426 17.624 9.45603C17.3334 10.0265 17.3334 10.7732 17.3334 12.2667V21.0667C17.3334 22.5601 17.3334 23.3069 17.624 23.8773C17.8797 24.3791 18.2876 24.787 18.7894 25.0427C19.3598 25.3333 20.1066 25.3333 21.6 25.3333H26.4C27.8935 25.3333 28.6403 25.3333 29.2107 25.0427C29.7124 24.787 30.1204 24.3791 30.3761 23.8773C30.6667 23.3069 30.6667 22.5601 30.6667 21.0667V13.3333L25.3334 8Z" stroke="#02203E" stroke-width="1.5" stroke-linejoin="round"/>
								</svg>
							</div>
						</div>
					</div>
				</div>
				<div className='grid-border mt-3'>
					<div className='grid grid-col-size align-center mb-12px'>
						<label className="block text-sm font-medium text-neutral-700 dark:text-neutral-200">Expiry Date</label>
						<div id='mq-card-exp' className='card-info-container'></div>
						<div id='copy-exp-container' className='clipboard-copy-container' style={{position: 'relative'}}>
							<div id="mq-copy-card-exp" style={{position: 'relative'}} >
								<svg width="34" height="34" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect width="40" height="40" rx="20" fill="#5B86E5" fill-opacity="0.2"/>
									<path d="M24 8V12.5333C24 13.2801 24 13.6534 24.1454 13.9387C24.2732 14.1895 24.4772 14.3935 24.7281 14.5213C25.0133 14.6667 25.3866 14.6667 26.1334 14.6667H30.6667M17.3334 14.6667H12C10.5273 14.6667 9.33337 15.8606 9.33337 17.3333V29.3333C9.33337 30.8061 10.5273 32 12 32H20C21.4728 32 22.6667 30.8061 22.6667 29.3333V25.3333M25.3334 8H21.6C20.1066 8 19.3598 8 18.7894 8.29065C18.2876 8.54631 17.8797 8.95426 17.624 9.45603C17.3334 10.0265 17.3334 10.7732 17.3334 12.2667V21.0667C17.3334 22.5601 17.3334 23.3069 17.624 23.8773C17.8797 24.3791 18.2876 24.787 18.7894 25.0427C19.3598 25.3333 20.1066 25.3333 21.6 25.3333H26.4C27.8935 25.3333 28.6403 25.3333 29.2107 25.0427C29.7124 24.787 30.1204 24.3791 30.3761 23.8773C30.6667 23.3069 30.6667 22.5601 30.6667 21.0667V13.3333L25.3334 8Z" stroke="#02203E" stroke-width="1.5" stroke-linejoin="round"/>
								</svg>
							</div>
						</div>
					</div>
				</div>
				<div className='mt-3'>
					<div className='grid grid-col-size align-center'>
						<label className="block text-sm font-medium text-neutral-700 dark:text-neutral-200">CVV</label>
						<div id='mq-card-cvv' className='card-info-container'></div>
						<div id='copy-cvv-container' className='clipboard-copy-container' style={{position: 'relative'}}>
							<div id="mq-copy-card-cvv" style={{position: 'relative'}} >
								<svg width="34" height="34" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect width="40" height="40" rx="20" fill="#5B86E5" fill-opacity="0.2"/>
									<path d="M24 8V12.5333C24 13.2801 24 13.6534 24.1454 13.9387C24.2732 14.1895 24.4772 14.3935 24.7281 14.5213C25.0133 14.6667 25.3866 14.6667 26.1334 14.6667H30.6667M17.3334 14.6667H12C10.5273 14.6667 9.33337 15.8606 9.33337 17.3333V29.3333C9.33337 30.8061 10.5273 32 12 32H20C21.4728 32 22.6667 30.8061 22.6667 29.3333V25.3333M25.3334 8H21.6C20.1066 8 19.3598 8 18.7894 8.29065C18.2876 8.54631 17.8797 8.95426 17.624 9.45603C17.3334 10.0265 17.3334 10.7732 17.3334 12.2667V21.0667C17.3334 22.5601 17.3334 23.3069 17.624 23.8773C17.8797 24.3791 18.2876 24.787 18.7894 25.0427C19.3598 25.3333 20.1066 25.3333 21.6 25.3333H26.4C27.8935 25.3333 28.6403 25.3333 29.2107 25.0427C29.7124 24.787 30.1204 24.3791 30.3761 23.8773C30.6667 23.3069 30.6667 22.5601 30.6667 21.0667V13.3333L25.3334 8Z" stroke="#02203E" stroke-width="1.5" stroke-linejoin="round"/>
								</svg>
							</div>
						</div>
					</div>
				</div>
			</div>
			{
				!cardLoaded &&
              <ButtonPrimary onClick={handleShowCard} loading={isLoadingCardToken || isLoadingCardPan}>Reveal card</ButtonPrimary>
			}
		</div>
	);
};

export default CardWidgetIframe;
