
import useOpenModal from 'hooks/modal/useOpenModal';
import { modals } from '../constants';
import useCurrentUser from 'hooks/useCurrentUserData';
import useHttpAuthMutation from 'hooks/useHttpAuthMutation';
import { useCallback } from 'react';

const backendBaseUrl = process.env.REACT_APP_BACKEND_CARDS_BASE_URL;

const useSellCard = (cardObjectId?: string, defaultPrice?: number):[()=>Promise<void>, boolean]=> {
	const [currentUserData, isLoadingCurrentUserData] = useCurrentUser();
	const payoutAccount = currentUserData?.payoutAccount;
	const payoutAccountEnabled = currentUserData?.payoutAccountEnabled;
	const [openPayoutAccountRequiredModal] = useOpenModal(modals.PAYOUT_ACCOUNT_REQUIRED_MODAL);
	const [openPayoutAccountBuildingModal] = useOpenModal(modals.PAYOUT_ACCOUNT_BUILDING_MODAL);
	const [openSellCardModal, closeSellCardModal] = useOpenModal(modals.SELL_CARD_MODAL);
	const { mutate: sellCardMutation, isLoading: isLoadingCardSell } = useHttpAuthMutation({
		baseUrl: backendBaseUrl,
		pathname: `/${cardObjectId}/sell`,
		method: 'POST',
	});
	const { mutate: resolveAccountSettingsUrl } = useHttpAuthMutation({
		baseUrl: backendBaseUrl,
		pathname: '/payout-account',
		method: 'PUT',
	});

	const configurePayoutAccount = useCallback(() => {
		return new Promise((resolve, reject) => {
			resolveAccountSettingsUrl({
				returnUrl: window.location.href
			}, {
				onSuccess: (res) => {
					window.location.href = (res as any).accountPortalUrl;
					return resolve(res);
				},
				onError: (err) => {
					return reject(err);
				}
			});
		});
	},[resolveAccountSettingsUrl]);

	const sellCardHandler = useCallback(async () => {
		if (!payoutAccount) {
			return openPayoutAccountRequiredModal({onSuccess: configurePayoutAccount});
		}
		if (!payoutAccountEnabled) {
			return openPayoutAccountBuildingModal({onAccountRedirect: configurePayoutAccount});
		}
		return openSellCardModal({
			defaultPrice,
			onSuccess: ({price}: {price: number}) => {
				closeSellCardModal();
				sellCardMutation({price});
			}
		});
	}, [payoutAccount, openPayoutAccountRequiredModal, configurePayoutAccount, payoutAccountEnabled, openPayoutAccountBuildingModal, defaultPrice]);
	return [sellCardHandler, isLoadingCardSell];
};

export default useSellCard;