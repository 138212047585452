import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import Input from 'shared/Input/Input';
import { Link, useNavigate } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import firebaseApp from '../../firebaseApp'; 

export interface ForgotPasswordProps {
  className?: string;
}


const ForgotPassword: FC<ForgotPasswordProps> = ({ className = '' }) => {
	// const dispatch = useAppDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const forgotPassword = async (email: string) => {
		setIsLoading(true);
		try {
			await firebaseApp.auth().sendPasswordResetEmail(email);
		} catch (err) {
			//
		}
		setIsLoading(false);
		navigate('/');
	};
    
	return (
		<div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
			<Helmet>
				<title>Forgot Password</title>
			</Helmet>
			<div className="container mb-24 lg:mb-32">
				<h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Forgot Password
				</h2>
				<div className="max-w-md mx-auto space-y-6">
					{/* FORM */}
					<form className="grid grid-cols-1 gap-6" onSubmit={async (event) => {
						event.preventDefault();
						await forgotPassword((event.target as any).elements.email.value);
					}}>
						<label className="block">
							<span className="text-neutral-800 dark:text-neutral-200">
                Email address
							</span>
							<Input
								type="email"
								name="email"
								placeholder="example@example.com"
								className="mt-1"
							/>
						</label>
						<ButtonPrimary loading={isLoading} type="submit">Continue</ButtonPrimary>
					</form>

					{/* ==== */}
					<span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {' '}
						<Link to="/signup" className=" text-15px_semi text-primary-1004 text-decor">
              Create an account
						</Link>
					</span>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
