import { useDoc } from '@rainbow-modules/firebase-hooks';
import useValidImageUrl from './useValidImageUrl';
import { businessImagesBucket } from '../constants';

interface IBusinessData {
    id: string;
    name: string;
    address: string;
    website: string;
    bio: string;
    balance: number;
    floorPrice: number;
    cards: number;
    owners: number;
    cardsListed: number;
    category: 'Restaurant';
    profileImageUrl?: string;
    twitter?: string;
}

const useBusiness = (businessId?: string): [IBusinessData | undefined, boolean] => {
	const [doc, isLoading] = useDoc<IBusinessData | undefined>({
		path: `businesses/${businessId}`,
		flat: true,
		disabled: !businessId
	});
	const [profileImageUrl, isLoadingProfileImage] = useValidImageUrl(businessId && `${businessImagesBucket}/${businessId}`);
	return [doc ? { profileImageUrl, ...doc } : undefined, isLoading || isLoadingProfileImage];
};

export default useBusiness;
