import Label from 'components/Label/Label';
import React, { FC, useState } from 'react';
import Avatar from 'shared/Avatar/Avatar';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Input from 'shared/Input/Input';
import Textarea from 'shared/Textarea/Textarea';
import useCurrentUserData from '../../hooks/useCurrentUserData';
import { Form, Field } from 'react-final-form';
import Spinner from 'components/Spinner';
import { pick } from 'lodash';
import existsUsername from 'utils/existsUsername';
import composeValidators from 'utils/composeValidators';
import updateCurrentUser, {IUserUpdates} from 'utils/updateCurrentUser';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from 'shared/Button/Button';
import { IUserData } from '../../hooks/useUserData';
import UserDataInput from 'containers/UserDataInput';
import UserProfileForm from './UserProfileForm';

export interface AccountPageProps {
    className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = '' }) => {
	const [userData] = useCurrentUserData();
	const navigate = useNavigate();
	const location = useLocation();

	return <UserProfileForm
		className={className}
		onCancel={() => {
			if(location?.state?.onCancelRedirect){
				return navigate(location.state.onCancelRedirect);
			}
			navigate(`/users/${userData?.uid}`);
		}}
		onUpdateSuccess={()=> {
			if(location?.state?.onSuccessRedirect){
				return navigate(location.state.onSuccessRedirect);
			}
		}}
	/>;
};

export default AccountPage;
