import React from 'react';
import ReactDOM from 'react-dom/client';
//
import './styles/index.scss';
import './index.css';
import './fonts/line-awesome-1.3.0/css/line-awesome.css';
import 'rc-slider/assets/index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { persistor, store } from 'app/store';
import { PersistGate } from 'redux-persist/integration/react';
import { FirebaseProvider } from '@rainbow-modules/firebase-hooks';
import app from './firebaseApp';
import {IntlProvider} from 'react-intl';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<IntlProvider locale="us" defaultLocale="en">
			<FirebaseProvider value={{ app: app as any }}>
				<Provider store={store}>
					<PersistGate loading={null} persistor={persistor}>
						<App />
					</PersistGate>
				</Provider>
			</FirebaseProvider>
		</IntlProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
