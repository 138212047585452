import React, { FC, Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import NcImage from 'shared/NcImage/NcImage';
import CardNFT from 'components/CardNFT';
import Pagination from 'shared/Pagination/Pagination';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import authorBanner from 'images/nfts/authorBanner.png';
import { nftsImgs } from 'contains/fakeData';
import NftMoreDropdown from 'components/NftMoreDropdown';
import ButtonDropDownShare from 'components/ButtonDropDownShare';
import SectionBecomeAnAuthor from 'components/SectionBecomeAnAuthor/SectionBecomeAnAuthor';
import SocialsList from 'shared/SocialsList/SocialsList';
import FollowButton from 'components/FollowButton';
import VerifyIcon from 'components/VerifyIcon';
import { Tab } from '@headlessui/react';
import CardAuthorBox3 from 'components/CardAuthorBox3/CardAuthorBox3';
import ArchiveFilterListBox from 'components/ArchiveFilterListBox';
import SectionGridAuthorBox from 'components/SectionGridAuthorBox/SectionGridAuthorBox';
import { useParams, useNavigate } from 'react-router-dom';
import useUserData from 'hooks/useUserData';
import { FormattedDate } from 'react-intl';
import useUserCards, { IFilter } from 'hooks/useUserCards';
import Spinner from 'components/Spinner';
import Card from 'components/Card';
import { useCurrentUser } from '@rainbow-modules/firebase-hooks';
import Button from 'shared/Button/Button';

export interface AuthorPageProps {
    className?: string;
}

const filterCategories = [
	'All cards',
	'Listed',
];

const defaultFilter: IFilter = {
	listed: undefined
};

const AuthorPage: FC<AuthorPageProps> = ({ className = '' }) => {
	const { userId } = useParams();
	const currentUser = useCurrentUser();
	const [userData, isLoadingUserData] = useUserData(userId);
	const [filter, setFilter] = useState(defaultFilter);
	const [cards, isLoadingCards] = useUserCards(userId, filter);
	const navigate = useNavigate();
	const isAuthenticatedUser = userId === currentUser?.uid;

	if (isLoadingUserData) {
		return <Spinner />;
	}

	const handleOnChangeFilter = (index: number) => {
		switch (index) {
		case 0:
			setFilter({
				...filter,
				listed: undefined,
			});
			break;
		case 1:
			setFilter({
				...filter,
				listed: true,
			});
			break;
		default:
			break;
		}
	};
	return (
		<div className={`nc-AuthorPage  ${className}`} data-nc-id="AuthorPage">

			{/* HEADER */}
			<div className="w-full ">
				<div className="container-custom container mt-10 lg:mt-16">
					<div className=" flex-start bg-white dark:bg-neutral-900 border dark:border dark:border-neutral-700 pb-1rem p-5 lg:p-8 rounded-3xl md:rounded-[40px] shadow-sm flex flex-col md:flex-row lg:items-center">
						<div className="w-36 lg:w-48 flex-shrink-0 sm:mt-0">
							<NcImage
								src={userData?.photoURL}
								containerClassName="aspect-w-1 aspect-h-1 border-radius overflow-hidden"
							/>
						</div>
						<div className="pt-5 md:pt-1 md:ml-6 xl:ml-4 flex-grow">
							<div className="sm:pl-0 xl:pl-6 max-w-screen-sm ">
								<h2 className="inline-flex items-center text-2xl sm:text-3xl lg:text-4xl font-semibold">
									<span>{`${userData?.firstName} ${userData?.lastName}`}</span>
								</h2>
								<div className="flex items-center text-sm font-medium space-x-2.5 mt-1 text-green-600 cursor-pointer">
									<span className="text-neutral-600 dark:text-neutral-300 text-16px_semi">
										{userData?.username}
									</span>
								</div>
							</div>
							<div className="grid grid-cols-3 lg:grid-cols-3 gap-2 sm:gap-4 xl:gap-6 elements_apart align-center">
								{/* ----- Cards ----- */}
								<div className="flex flex-col p-5 lg:p-6">
									<div className="flex flex-col">
										<svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
											<path d="M19.125 4.5H4.875C3.42525 4.5 2.25 5.67525 2.25 7.125V16.875C2.25 18.3247 3.42525 19.5 4.875 19.5H19.125C20.5747 19.5 21.75 18.3247 21.75 16.875V7.125C21.75 5.67525 20.5747 4.5 19.125 4.5Z" stroke="#5A686C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											<path d="M2.25 9H21.75M6 14.0625H8.25V15H6V14.0625Z" stroke="#5A686C" stroke-width="1.5" stroke-linejoin="round" />
										</svg>
										<span className="mb-4px text-14px_reg text-neutral-500 dark:text-neutral-400 pt-2">Cards</span>
										<span className="text-h5">{userData?.cards || 0}</span>
									</div>
								</div>

								{/* -----Listed ----- */}
								<div className="flex flex-col p-5 lg:p-6">
									<svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
										<path d="M7.36862 6.15788C7.92865 5.5735 10.4851 2.70687 10.8119 2.49791C11.1016 2.31265 11.4175 2.17613 11.7479 2.09336C12.1205 2 12.5166 2 13.3086 2L16.2356 2C18.0491 2 18.9558 2 19.6485 2.36828C20.2578 2.69222 20.7531 3.20912 21.0636 3.8449C21.4165 4.56768 21.4165 5.51386 21.4165 7.40621V10.4605C21.4165 11.2869 21.4165 11.7001 21.327 12.089C21.2477 12.4338 21.1169 12.7634 20.9393 13.0657C20.7391 13.4067 20.4591 13.6988 19.899 14.2832L14.826 19.5768C13.5437 20.9149 12.9025 21.584 12.1632 21.8346C11.5128 22.0551 10.8123 22.0551 10.1619 21.8346C9.42257 21.584 8.78138 20.9149 7.49905 19.5768L4.57206 16.5226C3.28973 15.1845 2.64855 14.5154 2.40832 13.7439C2.19701 13.0653 2.19701 12.3343 2.40832 11.6557C2.64855 10.8842 6.23834 7.33809 7.52067 6" stroke="#5A686C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M14.3486 9.37605C13.5507 8.59377 12.0877 7.73838 11.1749 8.68753C10.2026 9.69867 10.8754 11.0001 11.8338 12.0002C12.7922 13.0002 13.529 14.2315 12.4927 15.3128C11.7139 16.1255 10.4201 15.4883 9.34255 14.5998M14.3486 9.37605C15.0096 10.0566 15.5397 10.6691 15.9 11.2932M14.3486 9.37605L15.6672 8.00009M9.34255 14.5998C8.57585 13.7997 8.60647 13.8325 7.76788 12.7075M9.34255 14.5998L8.00022 16.0005" stroke="#5A686C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<ellipse cx="17.5833" cy="6" rx="0.958334" ry="1" stroke="#5A686C" stroke-width="1.5" />
									</svg>
									<span className="mb-4px text-14px_reg text-neutral-500 dark:text-neutral-400 pt-2">Listed</span>
									<span className="text-h5">{userData?.cardsListed || 0}</span>
								</div>

								{/* ----- Joined ----- */}
								<div className="flex flex-col p-5 lg:p-6">
									<svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
										<path d="M19.5 3.75H4.5C3.25736 3.75 2.25 4.75736 2.25 6V19.5C2.25 20.7426 3.25736 21.75 4.5 21.75H19.5C20.7426 21.75 21.75 20.7426 21.75 19.5V6C21.75 4.75736 20.7426 3.75 19.5 3.75Z" stroke="#5A686C" stroke-width="1.5" stroke-linejoin="round" />
										<path d="M13.875 12C14.4963 12 15 11.4963 15 10.875C15 10.2537 14.4963 9.75 13.875 9.75C13.2537 9.75 12.75 10.2537 12.75 10.875C12.75 11.4963 13.2537 12 13.875 12Z" fill="#5A686C" />
										<path d="M17.625 12C18.2463 12 18.75 11.4963 18.75 10.875C18.75 10.2537 18.2463 9.75 17.625 9.75C17.0037 9.75 16.5 10.2537 16.5 10.875C16.5 11.4963 17.0037 12 17.625 12Z" fill="#5A686C" />
										<path d="M13.875 15.75C14.4963 15.75 15 15.2463 15 14.625C15 14.0037 14.4963 13.5 13.875 13.5C13.2537 13.5 12.75 14.0037 12.75 14.625C12.75 15.2463 13.2537 15.75 13.875 15.75Z" fill="#5A686C" />
										<path d="M17.625 15.75C18.2463 15.75 18.75 15.2463 18.75 14.625C18.75 14.0037 18.2463 13.5 17.625 13.5C17.0037 13.5 16.5 14.0037 16.5 14.625C16.5 15.2463 17.0037 15.75 17.625 15.75Z" fill="#5A686C" />
										<path d="M6.375 15.75C6.99632 15.75 7.5 15.2463 7.5 14.625C7.5 14.0037 6.99632 13.5 6.375 13.5C5.75368 13.5 5.25 14.0037 5.25 14.625C5.25 15.2463 5.75368 15.75 6.375 15.75Z" fill="#5A686C" />
										<path d="M10.125 15.75C10.7463 15.75 11.25 15.2463 11.25 14.625C11.25 14.0037 10.7463 13.5 10.125 13.5C9.50368 13.5 9 14.0037 9 14.625C9 15.2463 9.50368 15.75 10.125 15.75Z" fill="#5A686C" />
										<path d="M6.375 19.5C6.99632 19.5 7.5 18.9963 7.5 18.375C7.5 17.7537 6.99632 17.25 6.375 17.25C5.75368 17.25 5.25 17.7537 5.25 18.375C5.25 18.9963 5.75368 19.5 6.375 19.5Z" fill="#5A686C" />
										<path d="M10.125 19.5C10.7463 19.5 11.25 18.9963 11.25 18.375C11.25 17.7537 10.7463 17.25 10.125 17.25C9.50368 17.25 9 17.7537 9 18.375C9 18.9963 9.50368 19.5 10.125 19.5Z" fill="#5A686C" />
										<path d="M13.875 19.5C14.4963 19.5 15 18.9963 15 18.375C15 17.7537 14.4963 17.25 13.875 17.25C13.2537 17.25 12.75 17.7537 12.75 18.375C12.75 18.9963 13.2537 19.5 13.875 19.5Z" fill="#5A686C" />
										<path d="M6 2.25V3.75M18 2.25V3.75" stroke="#5A686C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M21.75 7.5H2.25" stroke="#5A686C" stroke-width="1.5" stroke-linejoin="round" />
									</svg>
									<span className="mb-4px text-14px_reg text-neutral-500 dark:text-neutral-400 pt-2">Joined</span>
									<span className="text-h5"><FormattedDate value={userData?.createdAt} month="2-digit" day='2-digit' year='2-digit' /></span>
								</div>

							</div>
						</div>
						<div className="flex flex-row-reverse justify-end">
							<ButtonDropDownShare
								className="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-primary-1003 dark:hover:bg-primary-1002 dark:bg-neutral-800 cursor-pointer"
								panelMenusClass="origin-top-right !-right-5 !w-40 sm:!w-52"
							/>
							{
								userData?.twitter &&
                                <a
                                	href={userData?.twitter}
                                	className="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100  hover:bg-primary-1003 dark:hover:bg-primary-1002 dark:bg-neutral-800 cursor-pointer mx-2"
                                >
                                	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                		<path d="M23.25 5.13282C22.406 5.49955 21.513 5.74116 20.5992 5.85001C21.5595 5.28769 22.2817 4.39434 22.6303 3.33751C21.7224 3.86841 20.7307 4.24092 19.6978 4.43907C19.2629 3.98322 18.7397 3.62059 18.1603 3.3732C17.5808 3.12581 16.9571 2.99884 16.327 3.00001C13.7761 3.00001 11.7117 5.03438 11.7117 7.5422C11.7099 7.89102 11.7499 8.23881 11.8308 8.57813C10.0016 8.49238 8.2104 8.02575 6.57187 7.2081C4.93333 6.39044 3.48351 5.23977 2.31516 3.8297C1.90527 4.52069 1.6885 5.30909 1.6875 6.11251C1.6875 7.68751 2.50922 9.0797 3.75 9.89532C3.01487 9.87787 2.29481 9.68331 1.65094 9.32813V9.38438C1.65094 11.5875 3.24469 13.4203 5.35406 13.8375C4.9574 13.9433 4.54864 13.9968 4.13812 13.9969C3.84683 13.9974 3.5562 13.9691 3.27047 13.9125C3.85687 15.7172 5.56359 17.0297 7.58531 17.0672C5.94252 18.3333 3.9256 19.0175 1.85156 19.0125C1.48341 19.012 1.11561 18.99 0.75 18.9469C2.85993 20.2942 5.31255 21.0068 7.81594 21C16.3172 21 20.9616 14.0766 20.9616 8.07188C20.9616 7.87501 20.9564 7.67813 20.947 7.48595C21.8485 6.84472 22.6283 6.04787 23.25 5.13282Z" fill="#262626" />
                                	</svg>
                                </a>
							}
							{
								isAuthenticatedUser &&
								<Button className={`mr-16px button-edit text-16px_semi w-full border-bolder
                                        rounded-full justify-center border-neutral-1000 dark:border-primary-1002
                                        text-neutral-1000 dark:text-primary-1002 
                                        hover:bg-neutral-1000 hover:text-neutral-50 dark:hover:bg-primary-1004 dark:hover:border-primary-1004 dark:hover:text-neutral-50
                                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0 z-10`} onClick={() => navigate('/account')}>Edit Profile</Button>

							}

							{/* <FollowButton
                isFollowing={false}
                fontSize="text-sm md:text-base font-medium"
                sizeClass="px-4 py-1 md:py-2.5 h-8 md:!h-10 sm:px-6 lg:px-8"
              />*/}
						</div>
					</div>
				</div>
			</div>
			{/* ====================== END HEADER ====================== */}

			<div className="container-custom container py-16 lg:pb-28 lg:pt-10 space-y-16 lg:space-y-28">
				<main>
					<Tab.Group onChange={handleOnChangeFilter}>
						<div className="flex flex-col lg:flex-row justify-between ">
							<Tab.List className="flex space-x-0 sm:space-x-2 overflow-x-auto ">
								{filterCategories.map((item) => (
									<Tab key={item} as={Fragment}>
										{({ selected }) => (
											<button
												className={`flex-shrink-0 block font-medium px-4 py-2 text-sm sm:px-6 sm:py-2.5 capitalize rounded-full focus:outline-none ${selected
													? 'bg-neutral-900 dark:bg-neutral-100 text-neutral-50 dark:text-neutral-900'
													: 'text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100/70 dark:hover:bg-neutral-800'
												} `}
											>
												{item}
											</button>
										)}
									</Tab>
								))}
							</Tab.List>
							{/* <div className="mt-5 lg:mt-0 flex items-end justify-end">
                                <ArchiveFilterListBox />
                            </div> */}
						</div>
					</Tab.Group>
					{
						isLoadingCards &&
                        <Spinner />
					}


					<div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-6 mt-8 lg:mt-10">
						{
							!isLoadingCards && cards.length > 0 &&
                            cards.map(
                            	(cardData, index) => (
                            		<Card
                            			key={index}
                            			cardId={cardData.cardId}
                            			cardImageId={cardData.cardImageId}
                            			price={cardData.price}
                            			businessId={cardData.businessId}
                            			businessName={cardData.businessName}
                            			cardObjectId={cardData.id}
                            			tag={cardData.listed ? 'listed' : undefined}
                            			balance={cardData.balance}
                            			actionVariant={currentUser?.uid !== cardData.owner && cardData.listed ? 'buy' : undefined}
                            		/>
                            	)
                            )
						}
					</div>
					{
						!isLoadingCards && cards.length === 0 &&
                        (
                        	<div className="no-cards-container">No cards</div>
                        )
					}
				</main>

			</div>
		</div>
	);
};

export default AuthorPage;
