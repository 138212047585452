import React, { FC, useEffect, useRef } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Input from 'shared/Input/Input';
import NcModal from 'shared/NcModal/NcModal';
import CardWidget from './CardWidget';

export interface SellCardModalProps {
  show: boolean;
  onClose: () => void;
  cardObjectId: string,
}

const CardWidgetModal: FC<SellCardModalProps> = ({ show, onClose, cardObjectId }) => {
	const form = useRef(null);

	const renderContent = () => {
		return (
			<CardWidget cardObjectId={cardObjectId}/>
		);
	};

	const renderTrigger = () => {
		return null;
	};

	return (
		<NcModal
			isOpenProp={show}
			onCloseModal={onClose}
			contentExtraClass="max-w-lg"
			renderContent={renderContent}
			renderTrigger={renderTrigger}
			modalTitle=""
		/>
	);
};

export default CardWidgetModal;
