import * as auth from 'firebase/auth';
import { useFirebaseApp } from '@rainbow-modules/firebase-hooks';

function hasOwnProperty(obj, key) {
	return Object.prototype.hasOwnProperty.call(obj, key);
}

const getCallableKey = (obj, ...keys) => keys.find((k) => hasOwnProperty(obj, k));

/**
 * Resolves the Firebase Auth instance, regardless of the Firebase SDK version.
 * @param {FirebaseApp} app The firebase app
 * @returns Auth instance
 */
const useAuth = () => {
	const app = useFirebaseApp();
	if (!app) return null;

	// Firebase <= 8
	let key = getCallableKey(auth, 'auth');
	if (key) {
		return app[key]();
	}

	// Firebase 9
	key = getCallableKey(auth, 'getAuth');
	if (key) {
		return auth[key](app);
	}

	return null;
};

export default useAuth;
