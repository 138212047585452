import { useDoc } from '@rainbow-modules/firebase-hooks';
import { userProfilesImagesBucket } from '../constants';
import useValidImageUrl from './useValidImageUrl';

export interface IUserData {
    firstName: string;
    lastName: string;
    uid: string;
    photoURL?: string;
    username?: string;
    email: string;
    payoutAccount: string;
    payoutAccountEnabled: boolean;
    cards?: number;
    cardsListed?: number;
    createdAt?: Date;
    website?: string;
    twitter?: string;
    facebook?: string;
    telegram?: string;
    bio?: string;
    address?: {
        addressLine1: string;
        addressLine2?: string;
        city: string;
        countryCode: string;
        postalCode: string;
        state: string;
    },
    phoneNumber?: string;
    syncteraCustomerId?: string;
}

const normalizeUserData = (data: any, photoURL?: string): IUserData => {
	return {
		uid: data.id,
		photoURL,
		firstName: data.firstName,
		lastName: data.lastName,
		email: data.email,
		username: `@${data.username}`,
		payoutAccount: data.payoutAccount,
		payoutAccountEnabled: data.payoutAccountEnabled,
		cards: data.cards,
		cardsListed: data.cardsListed,
		createdAt: data.createdAt && typeof data.createdAt.toDate === 'function' ? data.createdAt.toDate() : undefined,
		website: data.website,
		twitter: data.twitter,
		facebook: data.facebook,
		telegram: data.telegram,
		bio: data.bio,
		address: data.address,
		phoneNumber: data.phoneNumber,
	};
};

const useUserData = (uid?: string): [IUserData | undefined, boolean] => {
	const [data, isLoading] = useDoc<IUserData>({
		path: `users/${uid}`,
		flat: true,
		disabled: !uid
	});
	const [imageUrl, isLoadingImageUrl] = useValidImageUrl(uid && `${userProfilesImagesBucket}/${uid}_1200x1200.jpeg`);
	if (isLoading || isLoadingImageUrl) {
		return [undefined, true];
	}
	return [data && normalizeUserData(data, imageUrl), false];
};

export default useUserData;