import Logo from 'shared/Logo/Logo';
import SocialsList1 from 'shared/SocialsList1/SocialsList1';
import { CustomLink } from 'data/types';
import React from 'react';

export interface WidgetFooterMenu {
    id: string;
    title: string;
    menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
	{
		id: '5',
		title: 'Getting started',
		menus: [
			{ href: '#', label: 'Installation' },
			{ href: '#', label: 'Release Notes' },
			{ href: '#', label: 'Upgrade Guide' },
			{ href: '#', label: 'Browser Support' },
			{ href: '#', label: 'Editor Support' },
			{ href: '#', label: 'Dark Mode' },
		],
	},
	{
		id: '1',
		title: 'Explore',
		menus: [
			{ href: '#', label: 'Design features' },
			{ href: '#', label: 'Prototyping' },
			{ href: '#', label: 'Design systems' },
			{ href: '#', label: 'Pricing' },
			{ href: '#', label: 'Customers' },
			{ href: '#', label: 'Security' },
		],
	},
	{
		id: '2',
		title: 'Resources',
		menus: [
			{ href: '#', label: 'Best practices' },
			{ href: '#', label: 'Support' },
			{ href: '#', label: 'Developers' },
			{ href: '#', label: 'Learn design' },
			{ href: '#', label: 'What\'s new' },
			{ href: '#', label: 'Releases' },
		],
	},
	{
		id: '4',
		title: 'Community',
		menus: [
			{ href: '#', label: 'Discussion Forums' },
			{ href: '#', label: 'Code of Conduct' },
			{ href: '#', label: 'Community Resources' },
			{ href: '#', label: 'Contributing' },
			{ href: '#', label: 'Concurrent Mode' },
			{ href: '#', label: 'API Reference' },
		],
	},
];

const Footer: React.FC = () => {
	//   const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
	//     return (
	//       <div key={index} className="text-sm">
	//         <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
	//           {menu.title}
	//         </h2>
	//         <ul className="mt-5 space-y-4">
	//           {menu.menus.map((item, index) => (
	//             <li key={index}>
	//               <a
	//                 key={index}
	//                 className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
	//                 href={item.href}
	//                 target="_blank"
	//                 rel="noopener noreferrer"
	//               >
	//                 {item.label}
	//               </a>
	//             </li>
	//           ))}
	//         </ul>
	//       </div>
	//     );
	//   };

	return (
		<div className="nc-Footer relative lg:pt-10 lg:pb-5 border-t border-neutral-200 dark:border-neutral-700">
			<div className="container flex justify-between mb-5">
				<div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
					<div className="col-span-2 md:col-span-1 mt-20px">
						<Logo />
					</div>
				</div>
				<div className="flex items-center gap-10">
					<a
						href={'https://discovered-trilby-0ec.notion.site/Terms-of-Service-888001ab080f4d919188fe81450dbe0c'}
						className="flex items-center"
					>
						<span className="hidden lg:block text-sm hover:text-decor">Terms and Conditions</span>

					</a>
					<a
						href={'https://discovered-trilby-0ec.notion.site/Privacy-Policy-a45517717e254053bf96ff12b3606c6a'}
						className="flex items-center"
					>
						<span className="hidden lg:block text-sm hover:text-decor">Privacy Policy</span>

					</a>
				</div>
			</div>
			<div className="container mb-5 text-15px_reg ">
                © {(new Date()).getFullYear()} ShowApp, C. Corp. All rights reserved. Built with ❤️ in Miami 🌴
			</div>
		</div>
	);
};

export default Footer;
