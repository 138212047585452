import Button, { ButtonProps } from 'shared/Button/Button';
import React from 'react';

export type ButtonSecondaryProps = ButtonProps

const ButtonSecondary: React.FC<ButtonSecondaryProps> = ({
	className = ' ',
	...args
}) => {
	return (
		<Button
			className={`ttnc-ButtonSecondary text-16px_semi border-size bg-white border-neutral-1000 text-neutral-700 dark:bg-neutral-900 dark:text-primary-1002 dark:border-primary-1002
			hover:bg-neutral-1000 hover:text-white dark:hover:bg-primary-1002 dark:hover:text-white ${className}`}
			{...args}
		/>
	);
};

export default ButtonSecondary;
