import { query, where } from 'firebase/firestore';
import useCards from './useCards';
import { useCallback } from 'react';

export interface IFilter {
    listed?: boolean;
}

const useBusinessCards = (businessId?: string, filter?: IFilter) => {
	const filterFn = useCallback((docRef: any) => {
		const wheres = [where('businessId', '==', businessId)];
		if (filter?.listed !== undefined) {
			wheres.push(where('listed', '==', filter.listed));
		}
		return query((docRef as any), ...wheres);
	}, [businessId, filter]);
	return useCards(filterFn);
};

export default useBusinessCards;
