import { query, where } from 'firebase/firestore';
import useCards from './useCards';
import { useCallback } from 'react';

export interface IFilter {
    listed?: boolean;
}

const useUserCards = (userId?: string, filter?: IFilter) => {
	const filterFn = useCallback((docRef: any) => {
		const wheres = [where('owner', '==', userId)];
		if (filter?.listed !== undefined) {
			wheres.push(where('listed', '==', filter.listed));
		}
		return query((docRef as any), ...wheres);
	}, [userId, filter]);
	return useCards(filterFn);
};

export default useUserCards;
