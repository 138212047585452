import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from 'components/Spinner';
import useHttpAuthMutation from 'hooks/useHttpAuthMutation';

const backendBaseUrl = process.env.REACT_APP_BACKEND_CARDS_BASE_URL;

export interface BuyCardRedirectProps {
    className?: string;
}

const BuyCardRedirect: FC<BuyCardRedirectProps> = ({
	className = '',
}) => {
	const { cardObjectId } = useParams();
	const { mutate: buyCardHandler, isLoading } = useHttpAuthMutation({
		baseUrl: backendBaseUrl,
		pathname: `/${cardObjectId}/buy`,
		method: 'POST',
	});
	useEffect(() => {
		if(!isLoading && buyCardHandler){
			const redirectTo = `${window.location.origin}/cards/${cardObjectId}`;
			buyCardHandler({
				successUrl: redirectTo || window.location.href,
				cancelUrl: redirectTo || window.location.href,
			}, {
				onSuccess: (res) => {
					window.location.href= (res as any).sessionUrl;
				}
			});
		}
	}, [buyCardHandler, isLoading]);
	return <Spinner />;
};

export default BuyCardRedirect;
