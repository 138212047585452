import React, { FC } from 'react';
import Input from 'shared/Input/Input';
import ButtonCircle from 'shared/Button/ButtonCircle';
import Card from 'components/Card';
import { useCurrentUser } from '@rainbow-modules/firebase-hooks';
import Spinner from 'components/Spinner';
import useMarketplaceCards from 'hooks/useMarketplaceCards';

export interface PageSearchProps {
    className?: string;
}


const PageSearch: FC<PageSearchProps> = ({ className = '' }) => {
	const currentUser = useCurrentUser();
	const [data, isLoading] = useMarketplaceCards();

	return (
		<div className={`nc-PageSearch  ${className}`} data-nc-id="PageSearch">
			<div className="container" id="nc-search-input-container">
				<header className="max-w-2xl mx-auto flex flex-col">
					<form className="relative w-full" method="post" id="nc-search-input">
						<label
							htmlFor="search-input"
							className="text-neutral-500 dark:text-neutral-300"
						>
							<span className="sr-only">Search all icons</span>
							<Input
								className="shadow-sm border-neutral-300 dark:border focus-dark"
								id="search-input"
								type="search"
								placeholder="Search cards and businesses..."
								sizeClass="pl-14 py-5 pr-5 md:pl-16"
								rounded="rounded-full"
								autoComplete='off'
							/>
							<ButtonCircle
								className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
								size=" w-11 h-11"
								type="submit"
							>
								<i className="las la-arrow-right text-xl"></i>
							</ButtonCircle>
							<span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
								<svg
									className="h-5 w-5"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M22 22L20 20"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</span>
						</label>
					</form>
				</header>
			</div>

			{
				isLoading &&
                <Spinner />
			}
			{
				!isLoading &&
                <div className="container-custom container py-16 lg:pb-28 lg:pt-5 space-y-16 lg:space-y-28">
                	<main>
                		{/* FILTER */}
                		{/* <HeaderFilterSearchPage /> */}

                		{/* LOOP ITEMS */}
                		<div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-6 mt-8 lg:mt-10">
                			{
                				data.map(
                					(cardData, index) => (
                						<Card
                							key={index}
                							cardId={cardData.cardId}
                							cardImageId={cardData.cardImageId}
                							price={cardData.price}
                							businessId={cardData.businessId}
                							businessName={cardData.businessName}
                							cardObjectId={cardData.id}
                							tag={currentUser?.uid===cardData.owner?'mine':'listed'}
                							balance={cardData.balance}
                							actionVariant={currentUser?.uid!==cardData.owner?'buy':undefined}
                						/>
                					)
                				)
                			}
                		</div>

                		{/* PAGINATION */}
                		<div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
                			{/* <Pagination /> */}
                			{/* <ButtonPrimary loading>Show me more</ButtonPrimary> */}
                		</div>
                	</main>

                	{/* === SECTION 5 === */}
                	{/* <div className="relative py-16 lg:py-28">
                <BackgroundSection />
                <SectionSliderCollections />
                </div> */}

                	{/* SUBCRIBES */}
                	{/* <SectionBecomeAnAuthor /> */}
                </div>

			}
		</div>
	);
};

export default PageSearch;
