import React from 'react';
import HeaderLogged from 'components/Header/HeaderLogged';
import Header2 from 'components/Header/Header2';
import { useAuthState, useCurrentUser } from '@rainbow-modules/firebase-hooks';

const SiteHeader = () => {
	const isAuth = useAuthState();
	const currentUser = useCurrentUser();
	currentUser?.getIdToken().then(console.log);
    
	return isAuth ? <HeaderLogged />: <Header2 />;
};

export default SiteHeader;
