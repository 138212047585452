import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import Avatar from 'shared/Avatar/Avatar';
import NcImage from 'shared/NcImage/NcImage';
import { nftsImgs } from 'contains/fakeData';
import ItemTypeImageIcon from './ItemTypeImageIcon';
import LikeButton from './LikeButton';
import Prices from './Prices';
import { ClockIcon } from '@heroicons/react/outline';
import ItemTypeVideoIcon from './ItemTypeVideoIcon';
import { cardImagesBucket, businessImagesBucket } from '../constants';
/*import {badgeBorder} from '../images/icons/badgeprofile.png';*/
import { FormattedNumber } from 'react-intl';
import useBuyCard from 'hooks/useBuyCard';
import Button from 'shared/Button/Button';
import {convertCentsToDollars} from '../utils/convertCentsToDollars';

export interface CardNFTProps {
    className?: string;
    isLiked?: boolean;
    cardId: number;
    price: number;
    cardImageId: string;
    businessId: string;
    businessName: string;
    cardObjectId: string;
    tag?: 'listed' | 'mine';
    actionVariant?: 'buy' | 'sell' | 'edit-listing';
    balance: number;
}

const tagToNameMap = {
	'listed': 'Listed',
	'mine': 'Listed'
};

const tagToClassMap = {
	'listed': 'pill-listed',
	'mine': 'pill-listed-mine'
};

const CardNFT: FC<CardNFTProps> = ({
	className = '',
	price,
	cardImageId,
	businessId,
	cardId,
	businessName,
	cardObjectId,
	tag,
	balance,
	actionVariant,
}) => {
	const [buyCardHandler, isLoadingBuyCardCheckoutSession] = useBuyCard(cardObjectId);
	//   const renderAvatars = () => {
	//     return (
	//       <div className="flex -space-x-1 ">
	//         <Avatar
	//           containerClassName="ring-2 ring-white dark:ring-neutral-900"
	//           sizeClass="h-5 w-5 text-sm"
	//         />
	//         <Avatar
	//           containerClassName="ring-2 ring-white dark:ring-neutral-900"
	//           sizeClass="h-5 w-5 text-sm"
	//         />
	//         <Avatar
	//           containerClassName="ring-2 ring-white dark:ring-neutral-900"
	//           sizeClass="h-5 w-5 text-sm"
	//         />
	//         <Avatar
	//           containerClassName="ring-2 ring-white dark:ring-neutral-900"
	//           sizeClass="h-5 w-5 text-sm"
	//         />
	//       </div>
	//     );
	//   };

	return (
		<div
			className={`nc-CardNFT relative flex flex-col group card-content [ nc-box-has-hover nc-dark-box-bg-has-hover ] ${className}`}
			data-nc-id="CardNFT"
		>
			<div className="relative flex-shrink-0 ">
				<div className="flex align-center elements_apart">
					<div className="flex align-center">
						<Avatar sizeClass="h-9 w-9 mr-2" radius="rounded-full " imgUrl={`${businessImagesBucket}/${businessId}`}/>
						<div>
							<p className="text-15px_semi text-neutral-900 dark:text-neutral-300 text-m ">{businessName}
								<span className="text-15px_reg text-neutral-500 dark:text-neutral-400 text-m"> #{cardId}</span></p>

						</div>
					</div>
					{tag && <button
						className={`${tagToClassMap[tag]} flex items-center justify-center rounded-full text-white`}
					>
						{tag === 'listed'} {/*&& <svg className="w-4 h-4" viewBox="0 0 17 17" fill="none">
							<path
								d="M4.89412 4.10527C5.28371 3.71568 7.06213 1.80459 7.28945 1.66528C7.49099 1.54178 7.71072 1.45076 7.94057 1.39558C8.19981 1.33334 8.47529 1.33334 9.02626 1.33334L11.0624 1.33334C12.324 1.33334 12.9548 1.33334 13.4366 1.57886C13.8605 1.79482 14.2051 2.13943 14.421 2.56328C14.6666 3.04513 14.6666 3.67592 14.6666 4.93748L14.6666 6.97365C14.6666 7.52461 14.6666 7.8001 14.6043 8.05934C14.5491 8.28919 14.4581 8.50891 14.3346 8.71046C14.1953 8.93778 14.0005 9.13258 13.6109 9.52216L10.0819 13.0512C9.18982 13.9433 8.74379 14.3893 8.22946 14.5564C7.77704 14.7034 7.2897 14.7034 6.83728 14.5564C6.32296 14.3893 5.87691 13.9433 4.98486 13.0512L2.94869 11.015C2.05663 10.123 1.61059 9.67695 1.44348 9.16262C1.29648 8.71021 1.29648 8.22287 1.44348 7.77045C1.6106 7.25612 4.10784 4.89207 4.9999 4.00001,
                  M9.74977 6.2507C9.19467 5.72918 8.17695 5.15892 7.54199 5.79169C6.86557 6.46578 7.33365 7.33343 8.00034 8.00012C8.66704 8.66682 9.1796 9.48766 8.4587 10.2086C7.91695 10.7503 7.01686 10.3255 6.26729 9.73318M9.74977 6.2507C10.2096 6.7044 10.5783 7.11276 10.829 7.52879M9.74977 6.2507L10.6671 5.3334M6.26729 9.73318C5.73393 9.19982 5.75523 9.22168 5.17187 8.47165M6.26729 9.73318L5.33349 10.667"
								stroke="currentColor"
								strokeWidth="1.2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>}*/}
						<span className="ml-1">{tagToNameMap[tag]}</span>
					</button>}
				</div>
				<div className="mtb-20">
					<NcImage
						containerClassName="flex aspect-w-12 aspect-h-8 w-full h-0 rounded-lg overflow-hidden z-0"
						src={`${cardImagesBucket}/${businessId}/${cardImageId}`}
						className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
					/>
				</div>
			</div>

			<div>
				<p className="text-13px_reg text-neutral-700 dark:text-neutral-400">Card balance</p>
				<p className="mb-0 text-h3 mt-4px text-neutral-900 dark:text-neutral-300">
					{`$${convertCentsToDollars(balance || 0)}`}
				</p>
				<div className="flex justify-between align-center">
					<div>
						<p className="text-13px_reg text-neutral-700 dark:text-neutral-400 text-m">
                            Price: <span className="text-15px_semi text-neutral-800 dark:text-neutral-300 text-m">{`$${convertCentsToDollars(price || 0)}`}</span>
						</p>
					</div>
					{
						actionVariant === 'buy' &&
                        <Button
                        	className={`button-sec 
                                        rounded-full justify-center bg-neutral-1000
                                        text-primary-50 dark:text-primary-50
                                        bg-neutral-1000 hover:bg-primary-1002 hover:text-neutral-50 
                                       	dark:bg-primary-1002 dark:hover:bg-primary-1001
                                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 
                                        dark:focus:ring-offset-0 z-10`}
                        	onClick={() => buyCardHandler && buyCardHandler()}
                        	loading={isLoadingBuyCardCheckoutSession}
                        > Buy Now </Button>

					}
				</div>



				{/* <h2 className={`text-lg font-medium`}>
          Card ID: {cardId}
        </h2> */}

			</div>

			<Link to={`/cards/${cardObjectId}`} className="absolute inset-0"></Link>
		</div>
	);
};

export default CardNFT;
