import { useEffect, useState } from 'react';

const checkImage = (path: string) =>
	new Promise((resolve, reject) => {
		const img = new Image();
		img.onload = () => resolve(path);
		img.onerror = () => resolve(undefined);

		img.src = path;
	});

const useValidImageUrl = (imageUrl?: string): [string | undefined, boolean] => {
	const [isLoadingImageUrl, setIsLoadingImageUrl] = useState(true);
	const [validImageUrl, setValidImageUrl] = useState<string | undefined>(undefined);
	useEffect(() => {
		setIsLoadingImageUrl(true);
		Promise.resolve(imageUrl ? checkImage(imageUrl) : undefined).then(imageURL => {
			setValidImageUrl(imageURL as string);
			setIsLoadingImageUrl(false);
		}).catch(() => {
			setIsLoadingImageUrl(false);
		});
	}, [imageUrl]);
	return [validImageUrl, isLoadingImageUrl];
};

export default useValidImageUrl;