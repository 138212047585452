

import Label from 'components/Label/Label';
import React, { FC, useState } from 'react';
import Avatar from 'shared/Avatar/Avatar';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Input from 'shared/Input/Input';
import Textarea from 'shared/Textarea/Textarea';
import useCurrentUserData from '../../hooks/useCurrentUserData';
import { Form, Field } from 'react-final-form';
import Spinner from 'components/Spinner';
import { pick } from 'lodash';
import existsUsername from 'utils/existsUsername';
import composeValidators from 'utils/composeValidators';
import updateCurrentUser, {IUserUpdates} from 'utils/updateCurrentUser';
import { useNavigate } from 'react-router-dom';
import Button from 'shared/Button/Button';
import isValidPhoneNumber  from 'utils/isValidPhoneNumber';
import { IUserData } from '../../hooks/useUserData';
import isValidUSStateCode from 'utils/isValidUSStateCode';

const required = (value: string) => (value ? undefined : 'Required');

const supportedCountryCodes = ['US'];
const phoneValidation = (value: string) => isValidPhoneNumber(value) ? undefined : 'Invalid phone number';
const countryCodeValidation = (value: string) => supportedCountryCodes.includes(value) ? undefined : 'Invalid country code';
const stateCodeValidation = (value: string) => isValidUSStateCode(value) ? undefined : 'Invalid state code';

interface UserDataFormProps {
    userData?: IUserData;
}

interface UserDataInputProps {
    userData?: IUserData;
}

export default function UserDataInput({userData}: UserDataInputProps) {
	if(!userData){
		return null;
	}
	const isUniqUsername = async (value: string) => {
		if (value && userData?.uid) {
			const existsUser = await existsUsername(value, userData.uid);
			if (existsUser) {
				return 'Username in use.';
			}
		}
		return undefined;
	};

	return <>
		{/* ---- */}
		<div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-5 sm:gap-2.5">
			<div>
				<Label>First name<span className="required">*</span></Label>
				<Field name='firstName'>
					{props => (
						<Input
							className="mt-1.5"
							placeholder="First name"
							value={props.input.value}
							onChange={props.input.onChange}
							name={props.input.name} />

					)}
				</Field>
			</div>
			<div>
				<Label>Last name<span className="required">*</span></Label>
				<Field name='lastName'>
					{props => (
						<Input
							className="mt-1.5"
							placeholder="Last name"
							value={props.input.value}
							onChange={props.input.onChange}
							name={props.input.name} />

					)}
				</Field>
			</div>
		</div>
		<div>
			<Label>Username</Label>
			<Field name='username' validate={composeValidators(required, isUniqUsername)}>
				{(props) => (
					<div>
						<div className="mt-1.5 flex">
							<span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                @
							</span>
							<Input
								className={`!rounded-l-none ${props.meta.error && props.meta.touched ? 'invalid-input' : ''}`}
								placeholder="example@email.com"
								{...props.input} />
						</div>
						{props.meta.error && props.meta.touched && <span className="input-error mt-4px">{props.meta.error}</span>}
					</div>
				)}
			</Field>
		</div>
		<div>
			<Label>Bio</Label>
			<Field name='bio'>
				{props => (
					<Textarea
						rows={5}
						className="mt-1.5"
						placeholder="Something about yourself in a few word."
						value={props.input.value}
						onChange={props.input.onChange}
						name={props.input.name} />
				)}
			</Field>
		</div>
		<div>
			<Label>Street Address<span className="required">*</span></Label>
			<Field name='address.addressLine1' validate={required}>
				{props => (
					<Input
						className={`mt-1.5 ${props.meta.error && props.meta.touched ? 'invalid-input' : ''}`}
						placeholder="Street Address"
						value={props.input.value}
						onChange={props.input.onChange}
						name={props.input.name} />
				)}
			</Field>
		</div>
		<div>
			<Label>Apartment/ Suite #</Label>
			<Field name='address.addressLine2'>
				{props => (
					<Input
						className={`mt-1.5 ${props.meta.error && props.meta.touched ? 'invalid-input' : ''}`}
						placeholder="Apartment/ Suite #"
						value={props.input.value}
						onChange={props.input.onChange}
						name={props.input.name} />

				)}
			</Field>
		</div>
		<div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-5 sm:gap-2.5">

			<div>
				<Label>City<span className="required">*</span></Label>
				<Field name='address.city' validate={required}>
					{props => (
						<Input
							className={`mt-1.5 ${props.meta.error && props.meta.touched ? 'invalid-input' : ''}`}
							placeholder="New York"
							value={props.input.value}
							onChange={props.input.onChange}
							name={props.input.name} />

					)}
				</Field>
			</div>
			<div>
				<Label>State<span className="required">*</span></Label>
				<Field name='address.state' validate={composeValidators(required, stateCodeValidation)}>
					{props => (
						<Input
							className={`mt-1.5 ${props.meta.error && props.meta.touched ? 'invalid-input' : ''}`}
							placeholder="NY"
							value={props.input.value}
							onChange={props.input.onChange}
							name={props.input.name} />

					)}
				</Field>
			</div>
		</div>
		<div className="grid lg:grid-cols-2 gap-5 sm:gap-2.5">
			<div>
				<Label>Zip Code<span className="required">*</span></Label>
				<Field name='address.postalCode' validate={required}>
					{props => (
						<Input
							className={`mt-1.5 ${props.meta.error && props.meta.touched ? 'invalid-input' : ''}`}
							placeholder="28620"
							value={props.input.value}
							onChange={props.input.onChange}
							name={props.input.name} />

					)}
				</Field>
			</div>
			<div>
				<Label>Country<span className="required">*</span></Label>
				<Field name='address.countryCode' validate={composeValidators(required, countryCodeValidation)}>
					{props => (
						<Input
							className={`mt-1.5 ${props.meta.error && props.meta.touched ? 'invalid-input' : ''}`}
							placeholder="US"
							value={props.input.value}
							onChange={props.input.onChange}
							name={props.input.name} />

					)}
				</Field>
			</div>
		</div>
		<div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-5 sm:gap-2.5">
			{/*<div>
            <Label>Facebook</Label>
            <Field name='facebook'>
                {props => (
                    <div className="mt-1.5 flex">
                        <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                            <i className="text-2xl lab la-facebook-f"></i>
                        </span>
                        <Input
                            className="!rounded-l-none"
                            placeholder="yourfacebook"
                            sizeClass="h-11 px-4 pl-2 pr-3"
                            value={props.input.value}
                            onChange={props.input.onChange}
                            name={props.input.name}
                        />
                    </div>
                )}
            </Field>

        </div>*/}
			<div>
				<Label>Phone number<span className="required">*</span></Label>
				<Field name='phoneNumber'  validate={composeValidators(required, phoneValidation)}>
					{props => (
						<Input
							className={`mt-1.5 ${props.meta.error && props.meta.touched ? 'invalid-input' : ''}`}
							placeholder="+12015554349"
							sizeClass="h-11 px-4 pl-2 pr-3"
							value={props.input.value}
							onChange={props.input.onChange}
							name={props.input.name} />
					)}
				</Field>
			</div>

			<div>
				<Label>Twitter</Label>
				<Field name='twitter'>
					{props => (
						<div className="mt-1.5 flex">
							<span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
								<i className="text-2xl lab la-twitter"></i>
							</span>
							<Input
								className="!rounded-l-none"
								placeholder="Twitter handle name"
								sizeClass="h-11 px-4 pl-2 pr-3"
								value={props.input.value}
								onChange={props.input.onChange}
								name={props.input.name} />
						</div>
					)}
				</Field>
			</div>
		</div>
		{/* <div>
            <Label>Email</Label>
            <div className="mt-1.5 flex">
                <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                    <i className="text-2xl las la-envelope"></i>
                </span>
                <Input
                    className="!rounded-l-none"
                    placeholder="example@email.com"
                />
            </div>
        </div> */}
	</>;
}

