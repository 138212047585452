import React, { FC } from 'react';
import NcImage from 'shared/NcImage/NcImage';
import CardNFT from 'components/CardNFT';
import Pagination from 'shared/Pagination/Pagination';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonDropDownShare from 'components/ButtonDropDownShare';
import TabFilters from 'components/TabFilters';
import { FormattedNumber } from 'react-intl';
import VerifyIcon from '../components/VerifyIcon';
import useBusiness from 'hooks/useBusiness';
import Spinner from 'components/Spinner';
import { useParams } from 'react-router-dom';
import { businessCategoryLabelMap } from '../constants';
import useBusinessCards from 'hooks/useBusinessCards';
import Card from 'components/Card';
import { useCurrentUser } from '@rainbow-modules/firebase-hooks';
import {convertCentsToDollars} from '../utils/convertCentsToDollars';

export interface PageCollectionProps {
    className?: string;
}

const PageCollection: FC<PageCollectionProps> = ({ className = '' }) => {
	const { businessId } = useParams();
	const [businessData, isLoadingBusiness] = useBusiness(businessId);
	const [cards, isLoadingCards] = useBusinessCards(businessId);
	const currentUser = useCurrentUser();

	if (isLoadingBusiness || isLoadingCards) {
		return <Spinner />;
	}

	return (
		<div
			className={`nc-PageCollection  ${className}`}
			data-nc-id="PageCollection"
		>

			{/* HEADER */}
			<div className="w-full">
				<div className=" container-custom relative container mt-2 lg:mt-2">
					<div className="flex-start  bg-white dark:bg-neutral-900 border dark:border dark:border-neutral-700 p-5 lg:p-8 rounded-3xl md:rounded-[40px] shadow-sm flex flex-col md:flex-row lg:items-center">
						<div className="flex flex-col sm:flex-row md:block sm:items-start sm:justify-between">
							<div className="">
								<div className=" business-logo w-36 sm:w-48 md:w-48 xl:w-48">
									<NcImage
										src={businessData?.profileImageUrl}
										containerClassName="aspect-w-1 aspect-h-1 border-radius overflow-hidden border dark:border border-neutral-200 dark:border-neutral-700"
									/>
								</div>
							</div>
						</div>
						<div className="sm:mt-10 md:mt-0 md:ml-8 xl:ml-4 flex-grow">
							<div className="sm:pl-0 xl:pl-6 max-w-screen-lg ">
								<h4 className="inline-flex items-center text-2xl sm:text-3xl lg:text-4xl font-semibold">
									<span>{businessData?.name}</span>
									<VerifyIcon
										className="ml-2"
										iconClass="w-6 h-6 sm:w-7 sm:h-7 xl:w-8 xl:h-8"
									/>
								</h4>
								<span className="block text-15px_reg text-neutral-500 dark:text-neutral-400 mt-4px">
									{businessData?.bio}
								</span>
							</div>
							<div className="grid grid-cols-2 lg:grid-cols-6 gap-2 sm:gap-4 xl:gap-6  flex-start">
								{/* ----- Ad balance ----- */}
								<div className="rounded-2xl flex flex-col justify-center p-5 lg:p-6">
									<svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
										<path d="M19.5 6.75H4.5C3.25736 6.75 2.25 7.75736 2.25 9V18C2.25 19.2426 3.25736 20.25 4.5 20.25H19.5C20.7426 20.25 21.75 19.2426 21.75 18V9C21.75 7.75736 20.7426 6.75 19.5 6.75Z" stroke="#5A686C" stroke-width="1.5" stroke-linejoin="round" />
										<path d="M19.2825 6.75022V5.34397C19.2824 4.99907 19.2062 4.65844 19.0592 4.34639C18.9123 4.03434 18.6984 3.75856 18.4326 3.53873C18.1668 3.3189 17.8558 3.16044 17.5217 3.07464C17.1877 2.98885 16.8388 2.97784 16.5 3.04241L4.155 5.14944C3.6189 5.2516 3.13526 5.53765 2.78749 5.95824C2.43972 6.37883 2.24963 6.9076 2.25 7.45335V9.75022" stroke="#5A686C" stroke-width="1.5" stroke-linejoin="round" />
										<path d="M17.25 15C16.9533 15 16.6633 14.912 16.4166 14.7472C16.17 14.5824 15.9777 14.3481 15.8642 14.074C15.7506 13.7999 15.7209 13.4983 15.7788 13.2074C15.8367 12.9164 15.9796 12.6491 16.1893 12.4393C16.3991 12.2296 16.6664 12.0867 16.9574 12.0288C17.2483 11.9709 17.5499 12.0006 17.824 12.1142C18.0981 12.2277 18.3324 12.42 18.4972 12.6666C18.662 12.9133 18.75 13.2033 18.75 13.5C18.75 13.8978 18.592 14.2794 18.3107 14.5607C18.0294 14.842 17.6478 15 17.25 15Z"
											fill="#5A686C" />
									</svg>
									<span className="mb-4px text-14px_reg text-neutral-500 dark:text-neutral-400 pt-2">Balance</span>
									<span className="text-h5">
										{`$${convertCentsToDollars(businessData?.balance || 0)}`}
									</span>
									{/* <span className="text-sm text-green-500">+2.11%</span>*/}
								</div>

								{/* ----- Floor price ----- */}
								<div className="rounded-2xl flex flex-col justify-center p-5 lg:p-6">
									<svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
										<path d="M12.3334 8.06384C11.1222 8.10162 9.33585 8.60498 9.33338 10.0311C9.33075 11.5504 10.8334 11.9997 12.3334 11.9999C13.8334 12 15.3334 12.3467 15.3334 13.9686C15.3334 15.1875 13.843 15.8231 12.3334 16M12.3334 8.06384C13.361 8.05699 14.3334 8.5 14.8334 9M12.3334 8.06384L12.3334 6M12.3334 16C10.8334 16 10.3334 15.5 9.33337 15M12.3334 16V18" stroke="#5A686C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<circle cx="12.3334" cy="12" r="9.25" stroke="#5A686C" stroke-width="1.5" />
									</svg>
									<span className="mb-4px text-14px_reg text-neutral-500 dark:text-neutral-400 pt-2">Floor price</span>
									<span className="text-h5">
										{`$${convertCentsToDollars(businessData?.floorPrice || 0)}`}
									</span>
								</div>
								{/* ----- Cards ----- */}
								<div className="rounded-2xl flex flex-col justify-center p-5 lg:p-6">
									<svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
										<path d="M19.125 4.5H4.875C3.42525 4.5 2.25 5.67525 2.25 7.125V16.875C2.25 18.3247 3.42525 19.5 4.875 19.5H19.125C20.5747 19.5 21.75 18.3247 21.75 16.875V7.125C21.75 5.67525 20.5747 4.5 19.125 4.5Z" stroke="#5A686C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M2.25 9H21.75M6 14.0625H8.25V15H6V14.0625Z" stroke="#5A686C" stroke-width="1.5" stroke-linejoin="round" />
									</svg>
									<span className="mb-4px text-14px_reg text-neutral-500 dark:text-neutral-400 pt-2">Cards</span>
									<span className="text-h5">{businessData?.cards || 0}</span>
								</div>

								{/* -----Owners ----- */}
								<div className="rounded-2xl flex flex-col justify-center p-5 lg:p-6">
									<svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
										<path d="M15.9872 6.23529C15.8096 8.56779 13.9936 10 12 10C10.0064 10 8.18722 8.56824 8.01278 6.23529C7.83154 3.80882 9.59861 2 12 2C14.4014 2 16.1685 3.85294 15.9872 6.23529Z" stroke="#5A686C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M11.9998 13C7.9218 13 3.7829 15.7 3.01699 20.7963C2.92465 21.4105 3.21432 22 3.75008 22H20.2494C20.7857 22 21.0754 21.4105 20.983 20.7963C20.2166 15.7 16.0777 13 11.9998 13Z" stroke="#5A686C" stroke-width="1.5" stroke-miterlimit="10" />
									</svg>
									<span className="mb-4px text-14px_reg text-neutral-500 dark:text-neutral-400 pt-2">Owners</span>
									<span className="text-h5">{businessData?.owners || 0}</span>
								</div>

								{/* -----Listed ----- */}
								<div className="rounded-2xl flex flex-col justify-center p-5 lg:p-6">
									<svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
										<path d="M7.36862 6.15788C7.92865 5.5735 10.4851 2.70687 10.8119 2.49791C11.1016 2.31265 11.4175 2.17613 11.7479 2.09336C12.1205 2 12.5166 2 13.3086 2L16.2356 2C18.0491 2 18.9558 2 19.6485 2.36828C20.2578 2.69222 20.7531 3.20912 21.0636 3.8449C21.4165 4.56768 21.4165 5.51386 21.4165 7.40621V10.4605C21.4165 11.2869 21.4165 11.7001 21.327 12.089C21.2477 12.4338 21.1169 12.7634 20.9393 13.0657C20.7391 13.4067 20.4591 13.6988 19.899 14.2832L14.826 19.5768C13.5437 20.9149 12.9025 21.584 12.1632 21.8346C11.5128 22.0551 10.8123 22.0551 10.1619 21.8346C9.42257 21.584 8.78138 20.9149 7.49905 19.5768L4.57206 16.5226C3.28973 15.1845 2.64855 14.5154 2.40832 13.7439C2.19701 13.0653 2.19701 12.3343 2.40832 11.6557C2.64855 10.8842 6.23834 7.33809 7.52067 6" stroke="#5A686C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M14.3486 9.37605C13.5507 8.59377 12.0877 7.73838 11.1749 8.68753C10.2026 9.69867 10.8754 11.0001 11.8338 12.0002C12.7922 13.0002 13.529 14.2315 12.4927 15.3128C11.7139 16.1255 10.4201 15.4883 9.34255 14.5998M14.3486 9.37605C15.0096 10.0566 15.5397 10.6691 15.9 11.2932M14.3486 9.37605L15.6672 8.00009M9.34255 14.5998C8.57585 13.7997 8.60647 13.8325 7.76788 12.7075M9.34255 14.5998L8.00022 16.0005" stroke="#5A686C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<ellipse cx="17.5833" cy="6" rx="0.958334" ry="1" stroke="#5A686C" stroke-width="1.5" />
									</svg>
									<span className="mb-4px text-14px_reg text-neutral-500 dark:text-neutral-400 pt-2">Listed</span>
									<span className="text-h5">{businessData?.cardsListed || 0}</span>
								</div>

								{/* -----Category ----- */}
								<div className="rounded-2xl flex flex-col justify-center p-5 lg:p-6">
									<svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
										<path d="M6.17601 22V8.00169M16.8242 22V18.5V8M6.17601 8.00169C6.43554 8 6.71831 8 7.02786 8H15.7593H16.8242M6.17601 8.00169C4.93557 8.00979 4.22604 8.05658 3.66095 8.32698C3.05987 8.6146 2.57118 9.07354 2.26492 9.63803C1.91675 10.2798 1.91675 11.1198 1.91675 12.8V17.2C1.91675 18.8802 1.91675 19.7202 2.26492 20.362C2.57118 20.9265 3.05987 21.3854 3.66095 21.673C4.34427 22 5.2388 22 7.02786 22H15.9723C17.7614 22 18.6559 22 19.3392 21.673C19.9403 21.3854 20.429 20.9265 20.7352 20.362C21.0834 19.7202 21.0834 18.8802 21.0834 17.2V12.8C21.0834 11.1198 21.0834 10.2798 20.7352 9.63803C20.429 9.07354 19.9403 8.6146 19.3392 8.32698C18.6559 8 17.7614 8 15.9723 8H16.8242" stroke="#5A686C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M9.75 2.75H13.25C15.0449 2.75 16.5 4.20507 16.5 6V7.75H11.5H6.5V6C6.5 4.20507 7.95507 2.75 9.75 2.75Z" stroke="#5A686C" stroke-width="1.5" />
									</svg>
									<span className="mb-4px text-14px_reg text-neutral-500 dark:text-neutral-400 pt-2">Category</span>
									<span className="text-h5">{businessData?.category && businessCategoryLabelMap[businessData?.category] ? businessCategoryLabelMap[businessData?.category] : 'Unknown'}</span>
								</div>
							</div>
						</div>
						<div className="flex flex-row-reverse justify-end">
							<ButtonDropDownShare
								className="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-primary-1003 dark:hover:bg-primary-1002 dark:bg-neutral-800 cursor-pointer"
								panelMenusClass="origin-top-right !-right-5 !w-40 sm:!w-52"
							/>
							{
								businessData?.twitter &&
                                <a target='_blank'
                                	href={businessData?.twitter}
                                	className="w-24 h-24 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100  hover:bg-primary-1003 dark:hover:bg-primary-1002 dark:bg-neutral-800 cursor-pointer mr-2" rel="noreferrer"
                                >
                                	<span className="icon-size lab la-twitter" />
                                </a>
							}
							{
								businessData?.website &&
                                <a target='_blank'
                                	href={businessData?.website}
                                	className="w-24 h-24 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100  hover:bg-primary-1003 dark:hover:bg-primary-1002 dark:bg-neutral-800 cursor-pointer mr-2" rel="noreferrer"
                                >
                                	<i className="icon-size las la-globe" />
                                </a>
							}
						</div>
					</div>
				</div>
			</div>
			{/* ====================== END HEADER ====================== */}

			<div className="container-custom container lg:pb-28 space-y-20 lg:space-y-28">
				<main>
					{/* TABS FILTER */}
					{/* <TabFilters /> */}

					{/* LOOP ITEMS */}
					<div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10  mt-8 lg:mt-10">
						{
							cards.map(
								(cardData, index) => (
									<Card
										key={index}
										cardId={cardData.cardId}
										cardImageId={cardData.cardImageId}
										price={cardData.price}
										businessId={cardData.businessId}
										businessName={cardData.businessName}
										cardObjectId={cardData.id}
										tag={cardData.listed ? 'listed' : undefined}
										balance={cardData.balance}
										actionVariant={currentUser?.uid !== cardData.owner && cardData.listed ? 'buy' : undefined}
									/>
								)
							)
						}
					</div>

					{/* PAGINATION */}
					<div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
						{/* <Pagination /> */}
						{/* <ButtonPrimary loading>Show me more</ButtonPrimary> */}
					</div>
				</main>

				{/* === SECTION 5 === */}
				{/*<div className="relative py-20 lg:py-28">*/}
				{/*  <BackgroundSection />*/}
				{/*  <SectionSliderCollections />*/}
				{/*</div>*/}

				{/* SUBCRIBES */}
				{/*<SectionBecomeAnAuthor />*/}
			</div>
		</div>
	);
};

export default PageCollection;
