// import useCard from 'hooks/useCard';
import useResolveCardToken from 'hooks/useCardToken';
import{ FC, useState } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { ClipboardCopyIcon } from '@heroicons/react/outline';

export interface CardWidgetProps {
    cardObjectId: string;
}

const CardWidget: FC<CardWidgetProps> = ({ cardObjectId }) => {
	// const [card, isLoadingCard] = useCard(cardObjectId);
	const [resolveCardToken, isLoadingCardToken] = useResolveCardToken(cardObjectId);
	const [isLoadingCardPan, setIsLoadingCardPan] = useState(false);
	const [cardLoaded, setCardLoaded] = useState(false);
	const isDarkMode= localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches);

	const clearDomId = (domId: string) => {
		const element = document.getElementById(domId);
		if (element) {
			element.innerHTML = ''; // Clear the content of the element

			// Create a new <div> element
			const newDiv = document.createElement('div');
			newDiv.innerHTML = `
      <svg width="24" height="24" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="30" height="30" rx="15" fill="#29860B"/>
        <path d="M21.25 10L12.5 20L8.75 16.25" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    `;

			// Append the new <div> to the target element
			element.appendChild(newDiv);
		}
	};

	const handlePanCopySuccess = () => {
		clearDomId('mq-copy-card-pan');
	};

	const handleExpCopySuccess = () => {
		clearDomId('mq-copy-card-exp');
	};

	const handleCvvCopySuccess = () => {
		clearDomId('mq-copy-card-cvv');
	};
	const handleShowCard = async () => {
		setIsLoadingCardPan(true);
		const token = await resolveCardToken();
		(window as any).marqeta.bootstrap({
			clientAccessToken: (token as any).token,
			component: {
				showPan: {
					'cardPan': {
						'domId': 'mq-card-pan',
						'format': true,
						'styles': {
							'span': {
								'font-family': 'OCR A Std, monospace',
								'line-height': '1',
								'font-weight': '400',
								'font-size': '15px',
								'color': isDarkMode ? 'white' : 'black'
							}
						}
					},
					'copyCardPan': {
						'domId': 'mq-copy-card-pan',
						'mode': 'transparent',
						'onCopySuccess': handlePanCopySuccess,
						// 'onCopyFailure': (error: any) => {
						// 	console.error(error);
						// }
					},
					'cardExp': {
						'domId': 'mq-card-exp',
						'format': true,
						'styles': {
							'span': {
								'font-family': 'OCR A Std, monospace',
								'line-height': '1',
								'font-weight': '400',
								'font-size': '15px',
								'color': isDarkMode ? 'white' : 'black'
							}
						}
					},
					'copyCardExp': {
						'domId': 'mq-copy-card-exp',
						'mode': 'transparent',
						'onCopySuccess': handleExpCopySuccess,
						// 'onCopyFailure': (error: any) => {
						// 	console.error(error);
						// }
					},
					'cardCvv': {
						'domId': 'mq-card-cvv',
						'styles': {
							'span': {
								'font-family': 'OCR A Std, monospace',
								'line-height': '1',
								'font-weight': '400',
								'font-size': '15px',
								'color': isDarkMode ? 'white' : 'black'
							}
						}
					},
					'copyCardCvv': {
						'domId': 'mq-copy-card-cvv',
						'mode': 'transparent',
						'onCopySuccess': handleCvvCopySuccess,
						// 'onCopyFailure': (error: any) => {
						// 	console.error(error);
						// }
					}
				}
			},
			'callbackEvents': {
				'onSuccess': () => {
					console.log('Success!');
					setCardLoaded(true);
					setIsLoadingCardPan(false);
				},
				'onFailure': (error: any) => {
					console.error(error);
					setIsLoadingCardPan(false);
				}
			}
		});
	};

	return (
		<div>
			<h2 className="text-h4 font-semibold text-neutral-900 dark:text-neutral-200">
                Card information
			</h2>
			<p className="text-neutral-500 dark:text-neutral-400">This is your card number. Use this card number to make online payments or add to your Apple wallet.</p>

			<div className='grid grid-cols-3 gap-4 mt-5 mb-5'>
				<label className="block text-16px_reg text-neutral-900 dark:text-neutral-200">Card number</label>
				<div id='mq-card-pan' className='card-info-container'></div>
				<div id='copy-pan-container' className='clipboard-copy-container' style={{position: 'relative'}}>
					<div id="mq-copy-card-pan" style={{position: 'relative'}} >
				        <ClipboardCopyIcon className="w-5 h-5 text-neutral-500" aria-hidden="true"></ClipboardCopyIcon>
					</div>
				</div>
				<label className="block text-16px_reg text-neutral-900 dark:text-neutral-200">Expiry Date</label>
				<div id='mq-card-exp' className='card-info-container'></div>
				<div id='copy-exp-container' className='clipboard-copy-container' style={{position: 'relative'}}>
					<div id="mq-copy-card-exp" style={{position: 'relative'}} >
				        <ClipboardCopyIcon className="w-5 h-5 text-neutral-500" aria-hidden="true"></ClipboardCopyIcon>
					</div>
				</div>
				<label className="block text-16px_reg text-neutral-900 dark:text-neutral-200">CVV</label>
				<div id='mq-card-cvv' className='card-info-container'></div>
				<div id='copy-cvv-container' className='clipboard-copy-container' style={{position: 'relative'}}>
					<div id="mq-copy-card-cvv" style={{position: 'relative'}} >
				        <ClipboardCopyIcon className="w-5 h-5 text-neutral-500" aria-hidden="true"></ClipboardCopyIcon>
					</div>
				</div>
			</div>
			{
				!cardLoaded &&
					<div className="flex flex-col justify-end">
						<ButtonPrimary className=" flex-sm" onClick={handleShowCard} loading={isLoadingCardToken || isLoadingCardPan}>Reveal card</ButtonPrimary>
					</div>

			}
		</div>
	);
};

export default CardWidget;
