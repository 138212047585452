import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

function isValidPhoneNumber(phoneNumber: string, region = 'US') {
	if (!phoneNumber) {
		return false;
	}

	// Check if the input is in the expected format (10 digits with no dashes or spaces, optionally with +1 prefix)
	const isValidFormat = /^(\+1)?\d{10}$/.test(phoneNumber);
	if (!isValidFormat) {
		return false;
	}

	// Remove any non-digits and format the input as "+1 (XXX) XXX-XXXX" before parsing it
	const formattedNumber = phoneNumber.replace(/\D/g, '').replace(/^(\d{1})(\d{3})(\d{3})(\d{4})$/, '+1 ($2) $3-$4');

	// Parse the phone number using the specified region
	const parsedNumber = phoneUtil.parseAndKeepRawInput(formattedNumber, region);
	return phoneUtil.isValidNumber(parsedNumber);
}

export default isValidPhoneNumber;
