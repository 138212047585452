import { useDoc } from '@rainbow-modules/firebase-hooks';
import { ICard } from './useCards';

const useCard = (cardId?: string): [ICard | undefined, boolean] => {
	return useDoc<ICard | undefined>({
		path: `cards/${cardId}`,
		flat: true,
		disabled: !cardId
	});
};

export default useCard;