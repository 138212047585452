import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Page } from './types';
import ScrollToTop from './ScrollToTop';
import Footer from 'shared/Footer/Footer';
import AuthorPage from 'containers/AuthorPage/AuthorPage';
import AccountPage from 'containers/AccountPage/AccountPage';
import PageSignUp from 'containers/PageSignUp/PageSignUp';
import PageLogin from 'containers/PageLogin/PageLogin';
import SiteHeader from 'containers/SiteHeader';
import CardDetailPage from 'containers/CardDetailPage';
import CardWidgetIframe from 'components/CardWidgetIframe';
import PageCollection from 'containers/PageCollection';
import PageSearch from 'containers/PageSearch';
import { useAuthState } from '@rainbow-modules/firebase-hooks';
import ForgotPassword from 'containers/PageForgotPass';
import Spinner from 'components/Spinner';
import useConnectModal from 'hooks/modal/useConnectModal';
import * as constants from '../constants';
import ModalPayoutAccountRequired from 'components/ModalPayoutAccountRequired';
import ModalPayoutAccountBuilding from 'components/ModalPayoutAccountBuilding';
import UserInfoRequiredModal from 'components/UserInfoRequiredModal';
import SellCardModal from 'components/SellCardModal';
import BuyCardRedirect from 'containers/BuyCardRedirect';
import CardWidgetModal from 'components/CardWidgetModal';

export const pages: Page[] = [
	{ path: '/', component: PageSearch },
	{ path: '/cards/:cardObjectId', component: CardDetailPage },
	{ path: '/cards/:cardObjectId/use/:token', component: CardWidgetIframe},
	{ path: '/cards/:cardObjectId/buy', component: BuyCardRedirect },
	{ path: '/businesses/:businessId', component: PageCollection },
	{ path: '/users/:userId', component: AuthorPage },
	{ path: '/account', component: AccountPage },
	{ path: '/forgot-pass', component: ForgotPassword, onlyUnauthenticated: true },
	{ path: '/signup', component: PageSignUp, onlyUnauthenticated: true },
	{ path: '/login', component: PageLogin, onlyUnauthenticated: true },
];


const ApplicationModals = () => {
	const payoutAccountRequiredModalProps = useConnectModal(constants.modals.PAYOUT_ACCOUNT_REQUIRED_MODAL);
	const payoutAccountBuildingModalProps = useConnectModal(constants.modals.PAYOUT_ACCOUNT_BUILDING_MODAL);
	const sellCardModalProps = useConnectModal(constants.modals.SELL_CARD_MODAL);
	const userInfoRequiredModalProps = useConnectModal(constants.modals.USER_INFO_REQUIRED_MODAL);
	const useCardWidgetModalProps = useConnectModal(constants.modals.CARD_WIDGET_MODAL);
	return (
		<>
			<ModalPayoutAccountRequired
				{...payoutAccountRequiredModalProps as any}
			/>
			<ModalPayoutAccountBuilding
				{...payoutAccountBuildingModalProps as any}
			/>
			<SellCardModal
				{...sellCardModalProps as any}
			/>
			<UserInfoRequiredModal
				{...userInfoRequiredModalProps as any}
			/>
			<CardWidgetModal
				{...useCardWidgetModalProps as any}
			/>
		</>
	);
};

const LayoutWithHeader: React.FC<React.PropsWithChildren<{}>> = ({children}) => (
	<>
		<SiteHeader />
		<div className='nc-content'>
			<div className='nc-hidden-header'/>
			{children}
		</div>
		<ApplicationModals />
	</>
);

const LayoutWithoutHeader: React.FC<React.PropsWithChildren<{}>> = ({children}) => (
	<>
		<div className='nc-content'>
			{children}
		</div>
		<ApplicationModals />
	</>
);



const MyRoutes = () => {
	const isAuth = useAuthState();
	const isLoading = typeof isAuth !== 'boolean';

	return (
		<BrowserRouter>
			<ScrollToTop />
			{/*<SiteHeader />*/}
			<div>
				{/*<div className='nc-hidden-header'/>*/}
				{
					isLoading ?
						<Spinner />
						: <Routes>
							{pages.filter(
								({ onlyUnauthenticated }) => !onlyUnauthenticated || (onlyUnauthenticated && !isAuth)
							).map(({ component, path
							}) => {
								const Component = component;
								const Layout = path.includes('/cards/') && path.includes('/use/') ? LayoutWithoutHeader : LayoutWithHeader;
								return (
									<Route key={path} path={path} element={
										<Layout>
											<Component />
										</Layout>
									} />
								);
							})}
							<Route
								path="*"
								element={<Navigate to="/" replace />}
							/>
						</Routes>
				}
			</div>
			<Footer />
			<ApplicationModals />
		</BrowserRouter>
	);
};


export default MyRoutes;

