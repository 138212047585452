import React, { FC, useState } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import NcModal from 'shared/NcModal/NcModal';

export interface ModalPayoutAccountBuildingProps {
  show: boolean;
  onClose: () => void;
  onAccountRedirect: () => void;
}

const ModalPayoutAccountBuilding: FC<ModalPayoutAccountBuildingProps> = ({ show, onClose, onAccountRedirect }) => {
	const [executingHandler, setExecutingHandler] = useState(false);
	const onAccountRedirectHandler = async ()=> {
		setExecutingHandler(true);
		await onAccountRedirect();
	};
	const renderContent = () => {
		return (
			<form action="#">
				<h3 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
            Payout account required
				</h3>
				<span className="text-sm">
            Your account information is incomplete, please enter the missing information if you want to sell your cards.
				</span>
				<div className="mt-4 space-x-3">
					<ButtonPrimary type="button" onClick={onAccountRedirectHandler} loading={executingHandler}>
            Go to account settings
					</ButtonPrimary>
					<ButtonSecondary type="button" onClick={onClose}>
            Cancel
					</ButtonSecondary>
				</div>
			</form>
		);
	};

	const renderTrigger = () => {
		return null;
	};

	return (
		<NcModal
			isOpenProp={show}
			onCloseModal={onClose}
			contentExtraClass="max-w-screen-sm"
			renderContent={renderContent}
			renderTrigger={renderTrigger}
			modalTitle=""
		/>
	);
};

export default ModalPayoutAccountBuilding;
