import React, { useState } from 'react';
import { Tab } from '@headlessui/react';
import { personNames } from 'contains/fakeData';
import Avatar from 'shared/Avatar/Avatar';
import VerifyIcon from 'components/VerifyIcon';
import { useCollection } from '@rainbow-modules/firebase-hooks';
import { useParams } from 'react-router-dom';
import useCardActivity, { IActivity } from 'hooks/useCardActivity';
import Spinner from 'components/Spinner';
import { FormattedNumber, FormattedDate } from 'react-intl';
import useUsers from 'hooks/useUsers';
import fromPairs from 'lodash.frompairs';
import {convertCentsToDollars} from '../../utils/convertCentsToDollars';

const TABS = ['All Activity', 'Listing', 'Sales'];

const tabToStateMap: Record<number, 'listing' | 'sale' | undefined> = {
	1: 'listing', 2: 'sale', 0: undefined
};

const mapActivityTypeToName = {
	sale: 'Sale',
	listing: 'Listing',
};

const useIdToUsernameMap = (cardActivity: IActivity[]): [Record<string, string | undefined>, boolean] => {
	const userIds = [
		...new Set<string>(
			([] as any).concat(
				...cardActivity.map(
					activityItem => [activityItem.owner, activityItem.previousOwner]
				)
			)
		)
	].filter(id => id !== undefined && id !== null);
	const [users, isLoading] = useUsers(userIds);
	return [fromPairs(users.map(user => [user.uid, user.username])), isLoading];
};

const CardActivity = ({ cardObjectId }: { cardObjectId: string; }) => {
	const [selectedType, setSelectedType] = useState<'listing' | 'sale' | undefined>();
	const [cardActivity, isLoadingCardActivity] = useCardActivity(cardObjectId || '', selectedType);
	const [userIdToUsernameMap, isLoadingUsernamesMap] = useIdToUsernameMap(cardActivity);
	const isLoading = isLoadingCardActivity || isLoadingUsernamesMap;

	return (
		<div className="w-full pdx-2 sm:px-0">
			<Tab.Group onChange={(tabSelected: number) => {
				const type = tabToStateMap[tabSelected];
				setSelectedType(type);
			}}>
				<Tab.List className="flex justify-start pd-1 space-x-2.5 rounded-full border-neutral-300 dark:border-neutral-500">
					{TABS.map((tab) => (
						<Tab
							key={tab}
							className={({ selected }) =>
								`px-3.5 sm:px-8 py-1.5 sm:py-2 text-xs sm:text-sm leading-5 text-14px_semi rounded-full focus:outline-none ${selected
									? 'bg-primary-1003 dark:bg-neutral-100 text-neutral-900 dark:text-neutral-900 text-14px_bold'
									: 'text-neutral-700 dark:text-neutral-300 bg-neutral-100/70 dark:bg-neutral-800 hover:text-neutral-900 dark:hover:text-neutral-100'
								}`
							}
						>
							{tab}
						</Tab>
					))}
				</Tab.List>
			</Tab.Group>
			<div className="activity-table-container">
				{
					isLoading &&
                    <Spinner className='activity-spinner-container' />
				}
				{
					!isLoading && cardActivity.length > 0 &&
                    <ul className="divide-y divide-neutral-100 dark:divide-neutral-700">
                    	{cardActivity.map((activityItem, index) => (
                    		<li
                    			key={index}
                    			className={`relative py-3 ${index % 2 === 1 ? 'bg-neutral-100' : ''
                    			}`}
                    		>
                    			<div className="grid grid-cols-4 gap-4 activity-row">
                    				<div className="flex items-center">
                    					<svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
                    						<path
                    							d="M2.25 3.75H5.25L7.5 16.5H19.5M7.5 13.5H19.1925C19.2792 13.5001 19.3633 13.4701 19.4304 13.4151C19.4975 13.3601 19.5434 13.2836 19.5605 13.1986L20.9105 6.44859C20.9214 6.39417 20.92 6.338 20.9066 6.28414C20.8931 6.23029 20.8679 6.18009 20.8327 6.13717C20.7975 6.09426 20.7532 6.05969 20.703 6.03597C20.6528 6.01225 20.598 5.99996 20.5425 6H6M9 19.5C9 19.9142 8.66421 20.25 8.25 20.25C7.83579 20.25 7.5 19.9142 7.5 19.5C7.5 19.0858 7.83579 18.75 8.25 18.75C8.66421 18.75 9 19.0858 9 19.5ZM19.5 19.5C19.5 19.9142 19.1642 20.25 18.75 20.25C18.3358 20.25 18 19.9142 18 19.5C18 19.0858 18.3358 18.75 18.75 18.75C19.1642 18.75 19.5 19.0858 19.5 19.5Z"
                    							stroke="#5A686C"
                    							strokeWidth="1.4"
                    							strokeLinecap="round"
                    							strokeLinejoin="round"
                    						/>
                    					</svg>
                    					<p className="ml-8px text-16px_semi text-neutral-900 dark:text-neutral-200">{mapActivityTypeToName[activityItem.type]}</p>
                    				</div>

                    				<div>
                    					<p className="text-14px_reg text-neutral-700 dark:text-neutral-400">from</p>
                    					<span className="text-15px text-primary-1001 dark:text-primary-1002 text-decor  ml-1">{activityItem.type === 'listing' ? userIdToUsernameMap[activityItem.owner] : (activityItem.previousOwner ? userIdToUsernameMap[activityItem.previousOwner] : 'business')}</span>
                    				</div>
                    				<div>
                    					{
                    						activityItem.type === 'sale' &&
                                            <>
                                            	<p className="text-14px_reg text-neutral-700 dark:text-neutral-400">to</p>
                                            	<span className="text-15px text-primary-1001 dark:text-primary-1002 text-decor  ml-1">{userIdToUsernameMap[activityItem.owner]}</span>
                                            </>
                    					}
                    				</div>
                    				<div>
                    					<p className="text-16px_bold text-neutral-900 dark:text-neutral-300">
                    						{`$${convertCentsToDollars(activityItem.price || 0)}`}
                    					</p>
                    					<span className="text-14px_reg text-neutral-700 dark:text-neutral-400  ml-1">
                    						<FormattedDate value={activityItem?.createdAt} />
                    					</span>
                    				</div>

                    			</div>

                    			<span className="absolute inset-0 rounded-md focus:z-10 focus:outline-none focus:ring-2 ring-blue-400"></span>
                    		</li>
                    	))}
                    </ul>
				}
				{
					!isLoading && cardActivity.length === 0 &&
                    <div style={{ display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center' }}>No activity</div>
				}
			</div>
		</div>
	);
};

export default CardActivity;
