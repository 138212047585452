export const cardImagesBucket = process.env.REACT_APP_CARDS_IMAGES_BUCKET;
export const userProfilesImagesBucket = process.env.REACT_APP_USER_PROFILES_IMAGES_BUCKETS;
export const businessImagesBucket= process.env.REACT_APP_BUSINESS_IMAGES_BUCKET;

export const businessCategoryLabelMap = {
	'Restaurant': 'Restaurant',
};

export const modals = {
	PAYOUT_ACCOUNT_REQUIRED_MODAL:'PAYOUT_ACCOUNT_REQUIRED_MODAL',
	PAYOUT_ACCOUNT_BUILDING_MODAL:'PAYOUT_ACCOUNT_BUILDING_MODAL',
	SELL_CARD_MODAL: 'SELL_CARD_MODAL',
	USER_INFO_REQUIRED_MODAL: 'USER_INFO_REQUIRED_MODAL',
	USER_PROFILE_MODAL:  'USER_PROFILE_MODAL',
	CARD_WIDGET_MODAL:  'CARD_WIDGET_MODAL',
};

export const businessCategoryIconMap = {
	'Restaurant': () => {
		return (
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clip-path="url(#clip0_250_9169)">
					<path d="M2.24565 1.86475L16.6374 16.2565C16.9105 16.5296 17.0639 16.9 17.0639 17.2862C17.0639 17.6724 16.9105 18.0428 16.6374 18.3159C16.3643 18.5889 15.9939 18.7422 15.6078 18.7422C15.2216 18.7422 14.8512 18.5889 14.5781 18.3159L11.0624 14.7397C10.8324 14.5061 10.7033 14.1915 10.7031 13.8636V13.6476C10.7031 13.482 10.6702 13.3181 10.6064 13.1653C10.5426 13.0126 10.449 12.874 10.3312 12.7577L9.87729 12.3386C9.7232 12.1964 9.53582 12.0953 9.3324 12.0445C9.12899 11.9937 8.91606 11.9949 8.71323 12.0479C8.39337 12.1314 8.05724 12.1298 7.7382 12.0433C7.41916 11.9567 7.1283 11.7883 6.89448 11.5546L3.55737 8.21709C1.57768 6.2374 0.849163 3.24795 2.24565 1.86475Z" stroke="#374EBD" stroke-width="1.5" stroke-linejoin="round" />
					<path d="M15.6249 1.25L12.6073 4.26758C12.3751 4.49973 12.1909 4.77536 12.0653 5.07871C11.9396 5.38206 11.8749 5.70719 11.8749 6.03555V6.61602C11.8749 6.69814 11.8588 6.77947 11.8273 6.85534C11.7959 6.93122 11.7498 7.00015 11.6917 7.0582L11.2499 7.5M12.4999 8.75L12.9417 8.3082C12.9998 8.25011 13.0687 8.20403 13.1446 8.17259C13.2204 8.14116 13.3018 8.12499 13.3839 8.125H13.9644C14.2927 8.125 14.6179 8.06032 14.9212 7.93465C15.2246 7.80898 15.5002 7.62478 15.7323 7.39258L18.7499 4.375M17.1874 2.8125L14.0624 5.9375M7.81241 14.375L3.9171 18.2922C3.62409 18.5851 3.22673 18.7497 2.81241 18.7497C2.39809 18.7497 2.00074 18.5851 1.70772 18.2922C1.4148 17.9992 1.25024 17.6018 1.25024 17.1875C1.25024 16.7732 1.4148 16.3758 1.70772 16.0828L4.99991 12.8125" stroke="#374EBD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
				</g>
				<defs>
					<clipPath id="clip0_250_9169">
						<rect width="20" height="20" fill="white" />
					</clipPath>
				</defs>
			</svg>
		);
	}
};
