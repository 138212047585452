import { getFirestore, setDoc, doc } from 'firebase/firestore';
import parsePhoneNumber from './parsePhoneNumber';

export interface IUserUpdates {
    username?: string
    firstName?: string
    lastName?: string
    twitter?: string
    facebook?: string
    telegram?: string
    bio?: string
    website?: string

    address?: {
        addressLine1: string;
        addressLine2?: string;
        city: string;
        countryCode: string;
        postalCode: string;
        state: string;
    },
    phoneNumber?: string;
}

const getNormalizedData = (data: IUserUpdates) => { 
	const normalized = {
		...data,
	};
	if(normalized.phoneNumber) {
		normalized.phoneNumber = parsePhoneNumber(normalized.phoneNumber);
	}
	if(normalized.address?.state) {
		normalized.address.state = normalized.address.state.toUpperCase();
	}
	return normalized;
};

const updateCurrentUser  = async (currentUserId: string, data: IUserUpdates) => {
	const db = getFirestore();
	await setDoc(doc(db, `users/${currentUserId}`), getNormalizedData(data), {merge: true});
};

export default updateCurrentUser;