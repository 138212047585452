import { useCollection } from '@rainbow-modules/firebase-hooks';
import { useEffect } from 'react';
import { useState } from 'react';

export interface ICard {
    id: string;
    cardId: number;
    price: number;
    cardImageId: string;
    businessId: string;
    businessName: string;
    owner: string;
    listed: boolean;
    balance: number;
}

type QueryFnType = ((ref: Record<string, unknown>) => void) | undefined;

const useCards = (queryFn: QueryFnType) => {
	return useCollection<ICard>({
		path: 'cards',
		query: queryFn,
		flat: true,
		track: [queryFn],
	});
};

export default useCards;