import { query, where, orderBy } from 'firebase/firestore';
import { useCollection } from '@rainbow-modules/firebase-hooks';
import { useState, useCallback } from 'react';

export interface IActivity {
    id: string;
    type: 'listing' | 'sale';
    owner: string;
    previousOwner?: string;
    price: number,
    createdAt: Date;
}


const normalizeActivity = (activity: IActivity[]) => {
	return activity.map(activityItem => ({
		...activityItem,
		createdAt: (activityItem.createdAt as any).toDate()
	}));
};
const useCardActivity = (cardId: string, type?: IActivity['type']): [IActivity[], boolean] => {
	const filterFn = useCallback((docRef: any) => {
		const wheres = [];
		if (type !== undefined) {
			wheres.push(where('type', '==', type));
		}
		return query((docRef as any), ...wheres, orderBy('createdAt', 'desc'));
	}, [type]);
	const [data, isLoading] = useCollection<IActivity>({
		path: `cards/${cardId}/activity`,
		query: filterFn,
		flat: true,
		track: [filterFn],
	});
	return [normalizeActivity(data), isLoading];
};

export default useCardActivity;