import { query, where } from 'firebase/firestore';
import useCards from './useCards';
import { useCallback } from 'react';

const useMarketplaceCards = () => {
	const filterFn = useCallback((docRef: any) => {
		const wheres = [where('listed', '==', true)];
		return query((docRef as any), ...wheres);
	}, []);
	return useCards(filterFn);
};

export default useMarketplaceCards;
