import React, { FC, useState } from 'react';
import appleSvg from 'images/Apple.svg';
import googleSvg from 'images/Google.svg';
import { Helmet } from 'react-helmet';
import Input from 'shared/Input/Input';
import { Link, redirect } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import firebaseApp from '../../firebaseApp'; 
import { GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import Logo from '../../shared/Logo/LogoOnly';

export interface PageLoginProps {
  className?: string;
}

const loginSocials = [
	{
		name: 'Continue with Apple',
		href: '#',
		icon: appleSvg,
		onCLick: async () => {
			const appleAuthProvider = new OAuthProvider('apple.com');
			await firebaseApp.auth().signInWithPopup(appleAuthProvider);
			redirect('/');
		}
	},
	{
		name: 'Continue with Google',
		href: '#',
		icon: googleSvg,
		onCLick: async () => {
			const googleAuthProvider = new GoogleAuthProvider();
			await firebaseApp.auth().signInWithPopup(googleAuthProvider);
			redirect('/');
		}
	},
];

const PageLogin: FC<PageLoginProps> = ({ className = '' }) => {
	// const dispatch = useAppDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const login = async (email: string, password: string) => {
		setIsLoading(true);
		try {
			await firebaseApp.auth().signInWithEmailAndPassword(email, password);
		} catch (error) {
			//
		}
		redirect('/');
		setIsLoading(false);
	};
    
	// const location = useLocation();
	return (
		<div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
			<Helmet>
				<title>Login | ShowApp Marketplace</title>
			</Helmet>
			<div className="container mb-8 lg:mb-32">
				<div className="flex flex-col items-center mb-40px">
					<Logo />
					<h2 className=" flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                  Login
					</h2>
				</div>

				<div className="max-w-md mx-auto space-y-6">
					<div className="grid gap-3">
						{loginSocials.map((item, index) => (
							<a
								key={index}
								href={item.href}
								onClick={item.onCLick}
								className="flex w-full rounded-lg bg-primary-100 dark:bg-neutral-800 text-neutral-800 dark:text-neutral-300 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
							>
								<img
									className="flex-shrink-0"
									src={item.icon}
									alt={item.name}
								/>
								<h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
									{item.name}
								</h3>
							</a>
						))}
					</div>
					{/* OR */}
					<div className="relative text-center">
						<span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
						</span>
						<div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
					</div>
					{/* FORM */}
					<form className="grid grid-cols-1 gap-6" onSubmit={async (event) => {
						event.preventDefault();
						await login((event.target as any).elements.email.value, (event.target as any).elements.password.value);
					}}>
						<label className="block">
							<span className="text-neutral-800 dark:text-neutral-200">
                Email address
							</span>
							<Input
								type="email"
								name="email"
								placeholder="example@example.com"
								className="mt-1"
							/>
						</label>
						<label className="block">
							<span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
								<Link to="/forgot-pass" className=" text-15px_semi text-primary-1004 text-decor">
                  Forgot password?
								</Link>
							</span>
							<Input type="password" className="mt-1" name="password" />
						</label>
						<ButtonPrimary loading={isLoading} type="submit" className="text-16px_semi">Continue</ButtonPrimary>
					</form>

					{/* ==== */}
					<span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {' '}
						<Link to="/signup" className=" text-15px_semi text-primary-1004 text-decor">
              Create an account
						</Link>
					</span>
				</div>
			</div>
		</div>
	);
};

export default PageLogin;
