import React, { FC, useEffect, useRef } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Input from 'shared/Input/Input';
import NcModal from 'shared/NcModal/NcModal';
import {convertCentsToDollars} from '../utils/convertCentsToDollars';

export interface SellCardModalProps {
  show: boolean;
  onClose: () => void;
  onSuccess: ({price}:{price:number}) => void;
  defaultPrice: number
}

const SellCardModal: FC<SellCardModalProps> = ({ show, onClose, onSuccess, defaultPrice = 100 }) => {
	const form = useRef(null);

	const renderContent = () => {
		return (
			<form ref={form}>
				<h3 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                    List card
				</h3>
				<span className="text-sm">Enter the price you want to list the card</span>
				<div className="mt-8 relative rounded-md shadow-sm">
					<Input name='price' defaultValue={convertCentsToDollars(defaultPrice)} type={'text'} />

					<div className="absolute inset-y-0 right-0 flex items-center">
						<label htmlFor="currency" className="sr-only">
                            Currency
						</label>
						<select
							id="currency"
							name="currency"
							className="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-neutral-500 dark:text-neutral-300 sm:text-sm rounded-md"
						>
							<option>USD</option>
						</select>
					</div>
				</div>
				<div className="mt-4 space-x-3">
					<ButtonPrimary type="button" onClick={() =>onSuccess({price: (form.current as any)['price'].value})}>Submit</ButtonPrimary>
					<ButtonSecondary type="button" onClick={onClose}>
                        Cancel
					</ButtonSecondary>
				</div>
			</form>
		);
	};

	const renderTrigger = () => {
		return null;
	};

	return (
		<NcModal
			isOpenProp={show}
			onCloseModal={onClose}
			contentExtraClass="max-w-lg"
			renderContent={renderContent}
			renderTrigger={renderTrigger}
			modalTitle=""
		/>
	);
};

export default SellCardModal;
