import Label from 'components/Label/Label';
import React, { FC, useState } from 'react';
import Avatar from 'shared/Avatar/Avatar';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Input from 'shared/Input/Input';
import Textarea from 'shared/Textarea/Textarea';
import useCurrentUserData from '../../hooks/useCurrentUserData';
import { Form, Field } from 'react-final-form';
import Spinner from 'components/Spinner';
import { pick } from 'lodash';
import existsUsername from 'utils/existsUsername';
import composeValidators from 'utils/composeValidators';
import updateCurrentUser, {IUserUpdates} from 'utils/updateCurrentUser';
import { useNavigate } from 'react-router-dom';
import Button from 'shared/Button/Button';
import { IUserData } from '../../hooks/useUserData';
import UserDataInput from 'containers/UserDataInput';

export interface UserProfileFormProps {
    className?: string;
    onCancel:  () => void;
    onUpdateSuccess?: () => void;
}

const UserProfileForm: FC<UserProfileFormProps> = ({ className = '', onCancel, onUpdateSuccess  }) => {
	const [userData, isLoadingUserData] = useCurrentUserData();
	const [isUpdatingUser, setIsUpdatingUser] = useState(false);
	if (isLoadingUserData || isUpdatingUser) {
		return <Spinner />;
	}    

	const initialValues = {
		username: userData?.username?.slice(1),
		...pick(userData, [
			'firstName',
			'lastName',
			'bio',
			'website',
			'twitter',
			'facebook',
			'telegram',
			'address',
			'phoneNumber'
		])
	};

	const handleSaveUser = async (user: IUserUpdates) => {
		if(userData?.uid){
			setIsUpdatingUser(true);
			await updateCurrentUser(userData.uid, user);
			setIsUpdatingUser(false);
			if(onUpdateSuccess){
				onUpdateSuccess();
			}
		}
	};

	return (
		<div className={`nc-UserProfileForm ${className}`} data-nc-id="UserProfileForm">
			<div className="container">
				<Form
					initialValues={initialValues}
					onSubmit={handleSaveUser}
					render={({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
								{/* HEADING */}
								<div className="max-w-2xl">
									<h2 className="text-3xl sm:text-4xl font-semibold">
                                        Profile settings
									</h2>
								</div>
								<div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
								<div className="flex flex-col md:flex-row">
									<div className="flex-shrink-0 flex items-start">
										<div className="relative rounded-full overflow-hidden flex">
											<Avatar sizeClass="w-32 h-32" imgUrl={userData?.photoURL} />
											<div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
												<svg
													width="30"
													height="30"
													viewBox="0 0 30 30"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
														stroke="currentColor"
														strokeWidth={1.5}
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>

												<span className="mt-1 text-xs">Change Image</span>
											</div>
											<input
												type="file"
												className="absolute inset-0 opacity-0 cursor-pointer"
											/>
										</div>
									</div>
									<div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-5 sm:space-y-6 md:sm:space-y-7">
										<UserDataInput userData={userData}/>
										<div className="pt-2 flex justify-between gap-3">
											<Button className={` text-16px_semi w-full border-bolder
                                        rounded-full justify-center border-neutral-1000 dark:border-primary-1002
                                        text-neutral-1000 dark:text-primary-1002 
                                        hover:bg-neutral-1000 hover:text-neutral-50 dark:hover:bg-primary-1004 dark:hover:border-primary-1004 dark:hover:text-neutral-50
                                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0 z-10`} onClick={onCancel}>Cancel</Button>
											<ButtonPrimary type='submit' className="w-full">Update profile</ButtonPrimary>
										</div>
									</div>
								</div>
							</div>
						</form>
					)}
				/>
			</div>
		</div>
	);
};

export default UserProfileForm;
