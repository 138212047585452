import { useNavigate, useLocation } from 'react-router-dom';
import useHttpAuthMutation from './useHttpAuthMutation';
import useCurrentUserData from './useCurrentUserData';
import { useCallback } from 'react';
import {get} from 'lodash';
import useOpenModal from 'hooks/modal/useOpenModal';
import { modals } from '../constants';

const backendBaseUrl = process.env.REACT_APP_BACKEND_CARDS_BASE_URL;

const canBuyCard = (user: any) => {
	const requiredFields = [
		'email',
		'firstName',
		'lastName',
		'phoneNumber',
		'address.addressLine1',
		'address.city',
		'address.countryCode',
		'address.postalCode',
		'address.state',
	];

	return user['syncteraCustomerId'] || requiredFields.reduce((acc, requiredField)=> acc && Boolean(get(user, requiredField)), true);
};

const useBuyCard = (cardObjectId?: string):[undefined |((successUrl?: string, cancelUrl?: string)=>Promise<void>), boolean]=> {
	const navigate = useNavigate();
	const location = useLocation();
	const [currentUserData, isLoadingCurrentUserData] = useCurrentUserData();
	const isAuthenticated = Boolean(currentUserData?.uid);
	const [openUserInfoRequiredModal, closeUserInfoRequiredModal] = useOpenModal(modals.USER_INFO_REQUIRED_MODAL);
	const { mutate, isLoading: isLoadingBuyCardCheckoutSession } = useHttpAuthMutation({
		baseUrl: backendBaseUrl,
		pathname: `/${cardObjectId}/buy`,
		method: 'POST',
	});
	const buyCardHandler = useCallback(async (successUrl?: string,  cancelUrl?: string) => {
		if(!isAuthenticated){
			navigate('/login');
			return;
		}

		if(!canBuyCard(currentUserData)) {
			openUserInfoRequiredModal({
				onSuccess: () => {
					navigate('/account', {
						state: {
							onSuccessRedirect: `/cards/${cardObjectId}/buy`,
							onCancelRedirect: location.pathname
						}
					});
					closeUserInfoRequiredModal();
				}
			});
			return;
		}
		return mutate({
			successUrl: successUrl || window.location.href,
			cancelUrl: cancelUrl || window.location.href,
		}, {
			onSuccess: (res) => {
				window.location.href= (res as any).sessionUrl;
			}
		});
	}, [isAuthenticated, mutate, navigate]);
	if(isLoadingCurrentUserData){
		return [undefined, isLoadingBuyCardCheckoutSession];
	}
	return [buyCardHandler, isLoadingBuyCardCheckoutSession];
};

export default useBuyCard;
